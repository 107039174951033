$primary-padding: 20px;
$primary-padding-double: calc(#{$primary-padding} * 2);

$cursor-empty: none !important; // url(https://i.ibb.co/Y205M6B/empty-circle.png) 12 12, auto !important;
$cursor-black: none !important; // url(https://i.ibb.co/6gFCK0T/dot.png) 12 12, auto !important;
$cursor-none: none !important;
$cursor-pointer: $cursor-black;



