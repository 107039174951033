@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,700,800,900");
@import url("https://fonts.googleapis.com/css?family=Noto+Serif:400,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;700&display=swap");
@font-face {
  font-family: 'swis721_blkex_btblack';
  src: url("./fonts/swissek-webfont.woff2") format("woff2"), url("./fonts/swissek-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

.text {
  font-family: "swis721_blkex_btblack";
  text-decoration: none; }
  .text.-secondary {
    font-family: "Raleway", sans-serif; }
  .text.-uppercase {
    text-transform: uppercase; }
  .text.-center {
    text-align: center; }
  .text.-left {
    text-align: left; }
  .text.-bold {
    font-weight: 900; }
  .text.-light {
    font-weight: 300; }
  .text.-light-hover {
    font-weight: 300; }
    .text.-light-hover:hover {
      font-weight: 600; }
  .text.-shadow {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4); }
  .text.-black {
    color: #000; }
  .text.-white {
    color: #FFF; }
  .text.-xxl {
    font-size: 30px; }
    @media (min-width: 768px) {
      .text.-xxl {
        font-size: 34px; } }
  .text.-xl {
    font-size: 22px; }
    @media (min-width: 768px) {
      .text.-xl {
        font-size: 24px; } }
  .text.-l {
    font-size: 18px; }
    @media (min-width: 768px) {
      .text.-l {
        font-size: 22px; } }
  .text.-s {
    font-size: 10px; }
    @media (min-width: 768px) {
      .text.-s {
        font-size: 16px; } }
  .text.-xs {
    font-size: 12px; }
    @media (min-width: 768px) {
      .text.-xs {
        font-size: 16px; } }
  .text.-xxs {
    font-size: 10px; }
    @media (min-width: 768px) {
      .text.-xxs {
        font-size: 12px; } }
  .text.-xsm {
    font-size: 12px; }
    @media (min-width: 768px) {
      .text.-xsm {
        font-size: 14px; } }
  .text.-pointer {
    cursor: none !important; }
  .text.-carniceria {
    color: #FF0057;
    font-family: "Noto Serif", serif; }
    .text.-carniceria:hover {
      font-weight: 900; }

html * {
  cursor: none !important; }

video {
  cursor: none !important; }

html,
body {
  overflow-x: hidden;
  cursor: none !important !important; }
  html .ljmdlk,
  html .kqCone,
  body .ljmdlk,
  body .kqCone {
    min-height: 100vh;
    overflow-y: auto; }
  html a,
  body a {
    cursor: none !important;
    text-decoration: none;
    color: #000; }
    html a:hover,
    body a:hover {
      cursor: none !important; }

.black-circle {
  cursor: none !important; }

.body {
  font-family: "swis721_blkex_btblack";
  overflow-x: hidden;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden; }
  .body:after {
    display: none;
    content: ''; }
  .body a {
    cursor: none !important;
    text-decoration: none;
    color: #000; }
    .body a:hover {
      cursor: none !important; }
  .body ul {
    padding-inline-start: 0;
    margin-block-start: 0;
    margin-block-end: 0; }
  .body h1,
  .body h2,
  .body h3,
  .body h4,
  .body h5,
  .body h6 {
    margin: 0; }
  .body .background-modal {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh; }
  .body .modal {
    padding: 20px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 11; }
    .body .modal.-video {
      padding: 0;
      width: calc(100% - 100px);
      height: calc(100% - 100px); }
  .body .close-modal-button {
    display: inline-block;
    position: fixed;
    z-index: 12;
    top: 20px;
    left: 50%;
    cursor: url(https://i.ibb.co/GPTVjWJ/add-3.png) 32 32, auto;
    transform: translate(-50%, 0); }
    .body .close-modal-button img {
      width: 30px; }

/** @define FlexEmbed */
/**
 * Flexible media embeds
 *
 * For use with media embeds – such as videos, slideshows, or even images –
 * that need to retain a specific aspect ratio but adapt to the width of their
 * containing element.
 *
 * Based on: http://alistapart.com/article/creating-intrinsic-ratios-for-video
 */
.FlexEmbed {
  display: block;
  overflow: hidden;
  position: relative; }

/**
 * The aspect-ratio hack is applied to an empty element because it allows
 * the component to respect `max-height`. Default aspect ratio is 1:1.
 */
.FlexEmbed-ratio {
  display: block;
  padding-bottom: 100%;
  width: 100%; }

/**
 * Modifier: 3:1 aspect ratio
 */
.FlexEmbed-ratio--3by1 {
  padding-bottom: calc(100% / 3); }

/**
 * Modifier: 2:1 aspect ratio
 */
.FlexEmbed-ratio--2by1 {
  padding-bottom: 50%; }

/**
 * Modifier: 16:9 aspect ratio
 */
.FlexEmbed-ratio--16by9 {
  padding-bottom: 56.25%; }

/**
 * Modifier: 4:3 aspect ratio
 */
.FlexEmbed-ratio--4by3 {
  padding-bottom: 75%; }

/**
 * Fit the content to the aspect ratio
 */
.FlexEmbed-content {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }

.l-office {
  position: relative;
  overflow: hidden;
  cursor: none !important; }
  .l-office a {
    cursor: none !important; }
    .l-office a:hover {
      cursor: none !important; }
  .l-office__image-section {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    height: 40%;
    background-size: contain;
    background-color: transparent;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    position: absolute; }
    @media (min-width: 768px) {
      .l-office__image-section {
        background-size: cover; } }
  .l-office__content {
    position: relative;
    width: calc(100% - 50px);
    margin: 110px auto;
    padding-right: 0%;
    z-index: 10; }
    .l-office__content__carniceria {
      text-align: right;
      display: none;
      margin-bottom: 80px; }
      @media (min-width: 768px) {
        .l-office__content__carniceria {
          display: block; } }
    @media (min-width: 768px) {
      .l-office__content {
        margin: 210px 0 0 30%;
        padding-right: 30%;
        width: calc(70% - 300px); } }
    .l-office__content__name {
      margin-top: 15px; }
    .l-office__content__section {
      margin-bottom: 50px; }
    .l-office__content__item-people {
      position: relative;
      margin-bottom: 60px;
      z-index: 20; }
      .l-office__content__item-people__name {
        margin-bottom: 10px; }
      .l-office__content__item-people__image {
        position: absolute;
        top: 50%;
        left: 60%;
        width: 60%;
        transform: translate(-50%, -50%);
        opacity: 0; }
  .l-office__box-contact-links {
    position: fixed;
    top: 100px;
    left: 60px;
    z-index: 19;
    display: flex;
    bottom: 0;
    top: initial;
    right: 10px;
    left: initial; }
    @media (min-width: 768px) {
      .l-office__box-contact-links {
        display: block;
        max-width: 260px;
        top: 100px;
        left: 60px;
        bottom: initial;
        right: initial; } }
    .l-office__box-contact-links__container {
      margin-bottom: 15px; }
      .l-office__box-contact-links__container .email-image {
        display: block;
        margin-right: 14px; }
        @media (min-width: 768px) {
          .l-office__box-contact-links__container .email-image {
            display: none; } }
      .l-office__box-contact-links__container__email-text {
        display: none; }
        @media (min-width: 768px) {
          .l-office__box-contact-links__container__email-text {
            display: block;
            margin-right: 10px; } }
      .l-office__box-contact-links__container__icon {
        width: 25px; }
