$cursorSize: 16px;
$cursorSizeHalf: 8px;

.cursor{
  position: absolute;
  z-index: 90;
  transform: translate(-50%, -50%);
  mix-blend-mode: exclusion;
  pointer-events: none;
  @media(max-width: 600px) {
    display:none
}
}

.main-cursor {
  width: $cursorSize;
  height: $cursorSize;
  z-index: 90;
  border-radius: 50%;
  background-color: black;
  border: 2px solid white;
  @media(max-width: 600px) {
    display:none
}
}



.clickable-cursor {
  width: $cursorSize;
  height: $cursorSize;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid white;
  background-color: black;
  animation: beat 0.5s infinite alternate;
  transform: translate(0, 0);
  transform-origin: $cursorSizeHalf $cursorSizeHalf;
  @media(max-width: 600px) {
    display:none
}
}



/* Heart beat animation */
@keyframes beat{
	to { transform: scale(1.2); }
}