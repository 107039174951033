@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;700&display=swap');
$filters-font: 'Raleway', sans-serif;

.filter-bar {
  width: 100%;
  display: flex;
  flex-basis: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;

  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  text-transform: lowercase;
  @media (min-width: 768px) {
    font-size: 15px;
    padding-left: 60px;
    padding-right: 60px;
  }
  color: white !important;
  font-family: $filters-font !important;
}

.filter-buttons {
  padding-right: 15px;
  @media (min-width: 768px) {
    padding-right: 40px;
  }
  .filter-b {
    padding-left: 5px;
    @media (min-width: 768px) {
      padding-left: 10px;
    }
    &:hover {
      font-weight: bolder;
    }
  }
}
.filter-button-more {
  margin-left: 15px;
  .filter-more {
    &:hover {
      font-weight: bolder;
    }
  }
}

.filter-count {
  font-weight: bold;
  white-space: nowrap;
}

.filters-current {
  display: flex;
  max-width: 80vw;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  overflow-x: hidden;
  background-attachment: local, local, scroll, scroll;
}

.filter-one {
  padding-left: 15px;
  white-space: nowrap;
  @media (min-width: 768px) {
    padding-left: 40px;
  }
  &.date {
    padding-left: 15px;
  }
  &.selected {
    font-weight: bold;
  }
  &:hover {
    font-weight: bolder;
  }
}

.filter-tooltip {
  background-color: rgba(black, 0.7);
  position: absolute;
  height: 25px;
  top: -40px;
  right: 350px;
  padding: 5px 15px 0px 15px;
  width: 60px;

  font-family: 'swis721_blkex_btblack', sans-serif;
  @media (max-width: 768px) {
    display: none;
  }
  &.hidden {
    opacity: 0;
    transition: opacity 0.5s;
  }
}
.filter-tooltip:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-width: 7px;
  border-style: solid;
  border-color: rgba(black, 0.7) transparent transparent transparent;

  left: 36px;
  top: 30px;
}
