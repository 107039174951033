@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,700,800,900");
@import url("https://fonts.googleapis.com/css?family=Noto+Serif:400,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;700&display=swap");
@font-face {
  font-family: 'swis721_blkex_btblack';
  src: url("./fonts/swissek-webfont.woff2") format("woff2"), url("./fonts/swissek-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

.text {
  font-family: "swis721_blkex_btblack";
  text-decoration: none; }
  .text.-secondary {
    font-family: "Raleway", sans-serif; }
  .text.-uppercase {
    text-transform: uppercase; }
  .text.-center {
    text-align: center; }
  .text.-left {
    text-align: left; }
  .text.-bold {
    font-weight: 900; }
  .text.-light {
    font-weight: 300; }
  .text.-light-hover {
    font-weight: 300; }
    .text.-light-hover:hover {
      font-weight: 600; }
  .text.-shadow {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4); }
  .text.-black {
    color: #000; }
  .text.-white {
    color: #FFF; }
  .text.-xxl {
    font-size: 30px; }
    @media (min-width: 768px) {
      .text.-xxl {
        font-size: 34px; } }
  .text.-xl {
    font-size: 22px; }
    @media (min-width: 768px) {
      .text.-xl {
        font-size: 24px; } }
  .text.-l {
    font-size: 18px; }
    @media (min-width: 768px) {
      .text.-l {
        font-size: 22px; } }
  .text.-s {
    font-size: 10px; }
    @media (min-width: 768px) {
      .text.-s {
        font-size: 16px; } }
  .text.-xs {
    font-size: 12px; }
    @media (min-width: 768px) {
      .text.-xs {
        font-size: 16px; } }
  .text.-xxs {
    font-size: 10px; }
    @media (min-width: 768px) {
      .text.-xxs {
        font-size: 12px; } }
  .text.-xsm {
    font-size: 12px; }
    @media (min-width: 768px) {
      .text.-xsm {
        font-size: 14px; } }
  .text.-pointer {
    cursor: none !important; }
  .text.-carniceria {
    color: #FF0057;
    font-family: "Noto Serif", serif; }
    .text.-carniceria:hover {
      font-weight: 900; }

html * {
  cursor: none !important; }

video {
  cursor: none !important; }

html,
body {
  overflow-x: hidden;
  cursor: none !important !important; }
  html .ljmdlk,
  html .kqCone,
  body .ljmdlk,
  body .kqCone {
    min-height: 100vh;
    overflow-y: auto; }
  html a,
  body a {
    cursor: none !important;
    text-decoration: none;
    color: #000; }
    html a:hover,
    body a:hover {
      cursor: none !important; }

.black-circle {
  cursor: none !important; }

.body {
  font-family: "swis721_blkex_btblack";
  overflow-x: hidden;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden; }
  .body:after {
    display: none;
    content: ''; }
  .body a {
    cursor: none !important;
    text-decoration: none;
    color: #000; }
    .body a:hover {
      cursor: none !important; }
  .body ul {
    padding-inline-start: 0;
    margin-block-start: 0;
    margin-block-end: 0; }
  .body h1,
  .body h2,
  .body h3,
  .body h4,
  .body h5,
  .body h6 {
    margin: 0; }
  .body .background-modal {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh; }
  .body .modal {
    padding: 20px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 11; }
    .body .modal.-video {
      padding: 0;
      width: calc(100% - 100px);
      height: calc(100% - 100px); }
  .body .close-modal-button {
    display: inline-block;
    position: fixed;
    z-index: 12;
    top: 20px;
    left: 50%;
    cursor: url(https://i.ibb.co/GPTVjWJ/add-3.png) 32 32, auto;
    transform: translate(-50%, 0); }
    .body .close-modal-button img {
      width: 30px; }

/** @define FlexEmbed */
/**
 * Flexible media embeds
 *
 * For use with media embeds – such as videos, slideshows, or even images –
 * that need to retain a specific aspect ratio but adapt to the width of their
 * containing element.
 *
 * Based on: http://alistapart.com/article/creating-intrinsic-ratios-for-video
 */
.FlexEmbed {
  display: block;
  overflow: hidden;
  position: relative; }

/**
 * The aspect-ratio hack is applied to an empty element because it allows
 * the component to respect `max-height`. Default aspect ratio is 1:1.
 */
.FlexEmbed-ratio {
  display: block;
  padding-bottom: 100%;
  width: 100%; }

/**
 * Modifier: 3:1 aspect ratio
 */
.FlexEmbed-ratio--3by1 {
  padding-bottom: calc(100% / 3); }

/**
 * Modifier: 2:1 aspect ratio
 */
.FlexEmbed-ratio--2by1 {
  padding-bottom: 50%; }

/**
 * Modifier: 16:9 aspect ratio
 */
.FlexEmbed-ratio--16by9 {
  padding-bottom: 56.25%; }

/**
 * Modifier: 4:3 aspect ratio
 */
.FlexEmbed-ratio--4by3 {
  padding-bottom: 75%; }

/**
 * Fit the content to the aspect ratio
 */
.FlexEmbed-content {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }

.l-work {
  position: relative;
  cursor: url(https://i.ibb.co/cLXqdFQ/output-onlinepngtools-2.png) 21 21, auto; }
  .l-work:scrollbar {
    display: none; }
  .l-work a:hover {
    cursor: url(https://i.ibb.co/cLXqdFQ/output-onlinepngtools-2.png) 21 21, auto; }
  .l-work__back-loader {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column; }
    .l-work__back-loader__bar {
      margin-top: 14px;
      height: 6px;
      width: 300px;
      transition: 0.1s linear; }
      .l-work__back-loader__bar__loader {
        height: 6px;
        background-color: #000; }
  .l-work__button-filters {
    position: fixed;
    display: none;
    align-content: center;
    align-items: center;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.8);
    top: 100px;
    right: 50px;
    padding: 10px;
    z-index: 8; }
    .l-work__button-filters.-clear {
      right: 160px; }
    @media (min-width: 768px) {
      .l-work__button-filters {
        display: flex; } }
    .l-work__button-filters__image {
      width: 15px;
      margin-right: 10px; }
  .l-work__project {
    display: inline-block;
    width: 100%;
    height: 300px;
    margin-top: -4px;
    background-repeat: no-repeat;
    background-size: cover; }
  .l-work .f20 {
    width: 10%;
    flex-basis: 10%;
    font-family: "swis721_blkex_btblack" !important;
    font-size: 12px !important;
    align-self: center;
    color: white;
    padding: 10px; }
  .l-work__filter-container {
    display: flex;
    right: initial;
    width: 90%;
    flex-basis: 90%;
    flex-direction: row;
    align-items: flex-end;
    /* width: calc(100% - 120px); */
    /* -webkit-transform: translate(-50%, 0); */
    /* transform: translate(-50%, 0); */
    z-index: 10;
    mix-blend-mode: normal !important; }
    .l-work__filter-container__title {
      padding: 5px;
      width: calc(100% / 3);
      margin-left: 0;
      text-align: center;
      color: white !important;
      font-family: "swis721_blkex_btblack" !important;
      text-decoration: none;
      font-size: 16px !important; }
      @media (min-width: 768px) {
        .l-work__filter-container__title {
          font-size: 24px; } }
      .l-work__filter-container__title:last-of-type {
        margin-right: 0; }
      .l-work__filter-container__title.-selectedFilter {
        font-weight: bold; }
    .l-work__filter-container__advanced-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      left: 0;
      width: 100%;
      color: white !important; }
      @media (min-width: 768px) {
        .l-work__filter-container__advanced-container {
          bottom: 0; } }
      .l-work__filter-container__advanced-container__options-container {
        display: flex;
        align-items: flex-end;
        left: 0;
        flex-wrap: wrap;
        flex-direction: column; }
        @media (min-width: 768px) {
          .l-work__filter-container__advanced-container__options-container {
            bottom: 0; } }
      .l-work__filter-container__advanced-container__options {
        margin-bottom: 5px;
        padding: 6px 10px;
        width: calc(100vw - 14px); }
        @media (min-width: 768px) {
          .l-work__filter-container__advanced-container__options {
            margin-bottom: 0; } }
        .l-work__filter-container__advanced-container__options__show-more {
          display: flex;
          justify-content: flex-end;
          margin-top: 5px;
          font-size: 12px; }
        .l-work__filter-container__advanced-container__options__title {
          display: block;
          margin-bottom: 10px;
          display: block; }
          @media (min-width: 768px) {
            .l-work__filter-container__advanced-container__options__title {
              display: none; } }
        .l-work__filter-container__advanced-container__options__container-items {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          padding: 0;
          display: flex;
          justify-content: center; }
          .l-work__filter-container__advanced-container__options__container-items__item {
            width: auto;
            margin-right: 30px;
            text-align: right;
            display: block; }
            @media (min-width: 768px) {
              .l-work__filter-container__advanced-container__options__container-items__item {
                display: inline-block; } }
            .l-work__filter-container__advanced-container__options__container-items__item:last-of-type {
              margin-right: 0; }
            .l-work__filter-container__advanced-container__options__container-items__item__triangle {
              width: 10px; }
            .l-work__filter-container__advanced-container__options__container-items__item__count {
              display: flex;
              flex-direction: row;
              align-content: center;
              align-items: baseline; }
              .l-work__filter-container__advanced-container__options__container-items__item__count.selected {
                text-decoration: underline; }
              .l-work__filter-container__advanced-container__options__container-items__item__count div {
                margin-left: 5px;
                font-size: 14px; }
      .l-work__filter-container__advanced-container__titles-container {
        display: flex;
        position: relative;
        flex-direction: row;
        margin-bottom: 0;
        padding: 5px;
        width: calc(100vw - 10px); }
      .l-work__filter-container__advanced-container__item {
        margin-left: 0;
        width: calc(100% / 3);
        text-align: center; }
        .l-work__filter-container__advanced-container__item:hover {
          font-weight: bolder; }
        .l-work__filter-container__advanced-container__item:first-of-type {
          margin-left: 0; }
  .l-work .imageloader .FlexEmbed-ratio {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%; }
    .l-work .imageloader .FlexEmbed-ratio.-selected {
      display: none;
      opacity: 0.3;
      -moz-filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
      -o-filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
      -webkit-filter: grayscale(100%);
      filter: gray; }
  .l-work .imageloader .image-loader-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center; }
  .l-work .imageloader .image-loader {
    width: 30px; }

.close {
  font-weight: bold !important;
  font-family: "Raleway", sans-serif !important; }

.filter-count {
  font-weight: bold !important;
  font-family: "Raleway", sans-serif !important; }

.filters-wrapper {
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 0px;
  width: 100%;
  flex-basis: 100%;
  height: 32px;
  z-index: 1; }

.project-grid {
  display: inline-block;
  position: relative;
  overflow: hidden;
  position: relative;
  width: calc(100% / 1);
  margin-top: -5px; }
  @media (min-width: 560px) {
    .project-grid {
      width: calc(100% / 1); } }
  @media (min-width: 720px) {
    .project-grid {
      width: calc(100% / 2); } }
  @media (min-width: 960px) {
    .project-grid {
      width: calc(100% / 3); } }
  @media (min-width: 1140px) {
    .project-grid {
      width: calc(100% / 4); } }
