@import url(https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Noto+Serif:400,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300;700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Noto+Serif:400,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300;700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Noto+Serif:400,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300;700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Noto+Serif:400,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300;700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Noto+Serif:400,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300;700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Noto+Serif:400,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300;700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Noto+Serif:400,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300;700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Noto+Serif:400,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300;700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Noto+Serif:400,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300;700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Noto+Serif:400,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300;700&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.react-draggable  {
  z-index: 20 !important
}
@charset "UTF-8";
@font-face {
  font-family: 'swis721_blkex_btblack';
  src: url(/static/media/swissek-webfont.0e33060b.woff2) format("woff2"), url(/static/media/swissek-webfont.9b53eabe.woff) format("woff");
  font-weight: normal;
  font-style: normal; }

.text {
  font-family: "swis721_blkex_btblack";
  text-decoration: none; }
  .text.-secondary {
    font-family: "Raleway", sans-serif; }
  .text.-uppercase {
    text-transform: uppercase; }
  .text.-center {
    text-align: center; }
  .text.-left {
    text-align: left; }
  .text.-bold {
    font-weight: 900; }
  .text.-light {
    font-weight: 300; }
  .text.-light-hover {
    font-weight: 300; }
    .text.-light-hover:hover {
      font-weight: 600; }
  .text.-shadow {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4); }
  .text.-black {
    color: #000; }
  .text.-white {
    color: #FFF; }
  .text.-xxl {
    font-size: 30px; }
    @media (min-width: 768px) {
      .text.-xxl {
        font-size: 34px; } }
  .text.-xl {
    font-size: 22px; }
    @media (min-width: 768px) {
      .text.-xl {
        font-size: 24px; } }
  .text.-l {
    font-size: 18px; }
    @media (min-width: 768px) {
      .text.-l {
        font-size: 22px; } }
  .text.-s {
    font-size: 10px; }
    @media (min-width: 768px) {
      .text.-s {
        font-size: 16px; } }
  .text.-xs {
    font-size: 12px; }
    @media (min-width: 768px) {
      .text.-xs {
        font-size: 16px; } }
  .text.-xxs {
    font-size: 10px; }
    @media (min-width: 768px) {
      .text.-xxs {
        font-size: 12px; } }
  .text.-xsm {
    font-size: 12px; }
    @media (min-width: 768px) {
      .text.-xsm {
        font-size: 14px; } }
  .text.-pointer {
    cursor: none !important; }
  .text.-carniceria {
    color: #FF0057;
    font-family: "Noto Serif", serif; }
    .text.-carniceria:hover {
      font-weight: 900; }

html * {
  cursor: none !important; }

video {
  cursor: none !important; }

html,
body {
  overflow-x: hidden;
  cursor: none !important !important; }
  html .ljmdlk,
  html .kqCone,
  body .ljmdlk,
  body .kqCone {
    min-height: 100vh;
    overflow-y: auto; }
  html a,
  body a {
    cursor: none !important;
    text-decoration: none;
    color: #000; }
    html a:hover,
    body a:hover {
      cursor: none !important; }

.black-circle {
  cursor: none !important; }

.body {
  font-family: "swis721_blkex_btblack";
  overflow-x: hidden;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden; }
  .body:after {
    display: none;
    content: ''; }
  .body a {
    cursor: none !important;
    text-decoration: none;
    color: #000; }
    .body a:hover {
      cursor: none !important; }
  .body ul {
    -webkit-padding-start: 0;
            padding-inline-start: 0;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0; }
  .body h1,
  .body h2,
  .body h3,
  .body h4,
  .body h5,
  .body h6 {
    margin: 0; }
  .body .background-modal {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh; }
  .body .modal {
    padding: 20px;
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 11; }
    .body .modal.-video {
      padding: 0;
      width: calc(100% - 100px);
      height: calc(100% - 100px); }
  .body .close-modal-button {
    display: inline-block;
    position: fixed;
    z-index: 12;
    top: 20px;
    left: 50%;
    cursor: url(https://i.ibb.co/GPTVjWJ/add-3.png) 32 32, auto;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0); }
    .body .close-modal-button img {
      width: 30px; }

/** @define FlexEmbed */
/**
 * Flexible media embeds
 *
 * For use with media embeds – such as videos, slideshows, or even images –
 * that need to retain a specific aspect ratio but adapt to the width of their
 * containing element.
 *
 * Based on: http://alistapart.com/article/creating-intrinsic-ratios-for-video
 */
.FlexEmbed {
  display: block;
  overflow: hidden;
  position: relative; }

/**
 * The aspect-ratio hack is applied to an empty element because it allows
 * the component to respect `max-height`. Default aspect ratio is 1:1.
 */
.FlexEmbed-ratio {
  display: block;
  padding-bottom: 100%;
  width: 100%; }

/**
 * Modifier: 3:1 aspect ratio
 */
.FlexEmbed-ratio--3by1 {
  padding-bottom: calc(100% / 3); }

/**
 * Modifier: 2:1 aspect ratio
 */
.FlexEmbed-ratio--2by1 {
  padding-bottom: 50%; }

/**
 * Modifier: 16:9 aspect ratio
 */
.FlexEmbed-ratio--16by9 {
  padding-bottom: 56.25%; }

/**
 * Modifier: 4:3 aspect ratio
 */
.FlexEmbed-ratio--4by3 {
  padding-bottom: 75%; }

/**
 * Fit the content to the aspect ratio
 */
.FlexEmbed-content {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }

.l-home {
  position: relative;
  max-height: 100vh;
  cursor: url(https://i.ibb.co/4dFGRKw/output-onlinepngtools-1.png) 21 21, auto; }
  @media (min-width: 768px) {
    .l-home {
      max-height: 100vh; } }
  .l-home__next-previous-image {
    height: 100vh;
    position: absolute;
    top: 0;
    width: 100%;
    -webkit-transition: .2s linear;
    transition: .2s linear;
    display: none;
    cursor: none !important; }
    .l-home__next-previous-image.-show {
      left: 90%; }
    .l-home__next-previous-image.-hidden {
      left: 100%; }
    @media (min-width: 768px) {
      .l-home__next-previous-image {
        display: block; } }
  .l-home__next-last-image {
    height: 100vh;
    position: absolute;
    top: 0;
    width: 100%;
    -webkit-transition: .2s linear;
    transition: .2s linear;
    z-index: 2;
    display: none;
    cursor: none !important; }
    .l-home__next-last-image.-show {
      left: -90%; }
    .l-home__next-last-image.-hidden {
      left: -100%; }
    @media (min-width: 768px) {
      .l-home__next-last-image {
        display: block; } }
  .l-home__news-slider {
    position: absolute;
    bottom: 0;
    z-index: 10;
    background: #000;
    padding: 5px; }
    .l-home__news-slider__container {
      position: absolute;
      bottom: 0;
      background-color: #000;
      width: 100%; }
      @media (min-width: 768px) {
        .l-home__news-slider__container {
          bottom: 10px;
          background-color: transparent; } }
      .l-home__news-slider__container__triangle-left {
        position: absolute;
        left: 20px;
        top: calc(50% - 3px);
        -webkit-transform: translate(0, -50%) rotate(-60deg);
                transform: translate(0, -50%) rotate(-60deg);
        z-index: 10;
        width: 18px; }
      .l-home__news-slider__container__triangle-right {
        position: absolute;
        right: 20px;
        top: calc(50% - 3px);
        -webkit-transform: translate(0, -50%) rotate(120deg);
                transform: translate(0, -50%) rotate(120deg);
        z-index: 10;
        width: 18px; }
    .l-home__news-slider__item {
      padding: 0;
      display: inline-block;
      max-width: calc(100% - 20px);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden; }
      @media (min-width: 768px) {
        .l-home__news-slider__item {
          padding: 0 60px;
          max-width: calc(100% - 120px); } }
  .l-home__dots-container {
    display: flex; }
    .l-home__dots-container.-home-page {
      position: relative;
      top: 40px; }
      @media (min-width: 768px) {
        .l-home__dots-container.-home-page {
          top: auto; } }
    .l-home__dots-container__ul-dots {
      position: relative;
      top: 20px; }
      @media (min-width: 768px) {
        .l-home__dots-container__ul-dots {
          top: auto; } }
    .l-home__dots-container__dot {
      position: relative; }
      .l-home__dots-container__dot__image {
        display: none;
        background-color: transparent;
        background-size: cover;
        width: 200px;
        height: 130px;
        position: absolute;
        top: -140px;
        left: 0;
        -webkit-transform: translate(-50%, 0);
                transform: translate(-50%, 0); }
        .l-home__dots-container__dot__image.-project-detail {
          background-size: contain;
          background-position: 50%;
          background-repeat: no-repeat; }
      .l-home__dots-container__dot:hover {
        cursor: url(https://i.ibb.co/4dFGRKw/output-onlinepngtools-1.png) 21 21, auto; }
        .l-home__dots-container__dot:hover .l-home__dots-container__dot__image {
          display: block; }
  .l-home__video-player {
    height: 100%;
    min-height: 100vh; }
    .l-home__video-player video {
      object-fit: cover; }
  .l-home__loading-container {
    display: flex;
    min-height: 100vh;
    align-items: center;
    align-content: center;
    justify-content: center; }
  .l-home__slider-item {
    min-height: 100vh;
    outline: none;
    background-repeat: no-repeat;
    background-size: cover; }
    .l-home__slider-item__cover-image {
      min-height: 100vh;
      background-repeat: no-repeat;
      background-size: cover; }
      .l-home__slider-item__cover-image.-project-detail {
        min-height: 100vh;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: contain; }
  .l-home .slick-list {
    height: 100%; }
  .l-home .slick-dots {
    display: flex;
    position: relative;
    top: -120px;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    margin-left: 0;
    text-align: center;
    padding: 0; }
    @media (min-width: 768px) {
      .l-home .slick-dots {
        flex-direction: row; } }
    .l-home .slick-dots:hover {
      cursor: url(https://i.ibb.co/4dFGRKw/output-onlinepngtools-1.png) 21 21, auto; }
    .l-home .slick-dots ul {
      margin-bottom: 5px;
      padding: 0; }
      @media (min-width: 768px) {
        .l-home .slick-dots ul {
          margin-bottom: 0; } }
    .l-home .slick-dots li {
      display: inline-block;
      width: 12px;
      height: 12px;
      margin-right: 20px;
      padding: 0;
      -webkit-transition: .1s linear;
      transition: .1s linear;
      border: 3px solid #FFF;
      border-radius: 100%;
      background-color: transparent;
      cursor: none !important; }
      .l-home .slick-dots li:hover {
        cursor: url(https://i.ibb.co/4dFGRKw/output-onlinepngtools-1.png) 21 21, auto; }
      .l-home .slick-dots li.-selected {
        background-color: #FFF; }
  .l-home .slick-slider {
    height: 100%; }
  .l-home__triangle-left {
    position: absolute;
    left: 10px;
    top: 50%;
    -webkit-transform: translate(0, -50%) rotate(-90deg);
            transform: translate(0, -50%) rotate(-90deg);
    z-index: 10;
    width: 60px;
    display: none; }
    @media (min-width: 768px) {
      .l-home__triangle-left {
        display: block; } }
  .l-home__triangle-right {
    position: absolute;
    right: 10px;
    top: 50%;
    -webkit-transform: translate(0, -50%) rotate(90deg);
            transform: translate(0, -50%) rotate(90deg);
    z-index: 10;
    width: 60px;
    display: none; }
    @media (min-width: 768px) {
      .l-home__triangle-right {
        display: block; } }
  .l-home .link-slider-project {
    display: inline-block;
    margin-top: 5px; }
  .l-home .relative-mobile-title {
    position: relative;
    top: -55px; }
    @media (min-width: 768px) {
      .l-home .relative-mobile-title {
        top: auto; } }
  .l-home .relative-mobile-video-title {
    position: relative;
    top: 25px; }
    @media (min-width: 768px) {
      .l-home .relative-mobile-video-title {
        top: auto; } }

@charset "UTF-8";
@font-face {
  font-family: 'swis721_blkex_btblack';
  src: url(/static/media/swissek-webfont.0e33060b.woff2) format("woff2"), url(/static/media/swissek-webfont.9b53eabe.woff) format("woff");
  font-weight: normal;
  font-style: normal; }

.text {
  font-family: "swis721_blkex_btblack";
  text-decoration: none; }
  .text.-secondary {
    font-family: "Raleway", sans-serif; }
  .text.-uppercase {
    text-transform: uppercase; }
  .text.-center {
    text-align: center; }
  .text.-left {
    text-align: left; }
  .text.-bold {
    font-weight: 900; }
  .text.-light {
    font-weight: 300; }
  .text.-light-hover {
    font-weight: 300; }
    .text.-light-hover:hover {
      font-weight: 600; }
  .text.-shadow {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4); }
  .text.-black {
    color: #000; }
  .text.-white {
    color: #FFF; }
  .text.-xxl {
    font-size: 30px; }
    @media (min-width: 768px) {
      .text.-xxl {
        font-size: 34px; } }
  .text.-xl {
    font-size: 22px; }
    @media (min-width: 768px) {
      .text.-xl {
        font-size: 24px; } }
  .text.-l {
    font-size: 18px; }
    @media (min-width: 768px) {
      .text.-l {
        font-size: 22px; } }
  .text.-s {
    font-size: 10px; }
    @media (min-width: 768px) {
      .text.-s {
        font-size: 16px; } }
  .text.-xs {
    font-size: 12px; }
    @media (min-width: 768px) {
      .text.-xs {
        font-size: 16px; } }
  .text.-xxs {
    font-size: 10px; }
    @media (min-width: 768px) {
      .text.-xxs {
        font-size: 12px; } }
  .text.-xsm {
    font-size: 12px; }
    @media (min-width: 768px) {
      .text.-xsm {
        font-size: 14px; } }
  .text.-pointer {
    cursor: none !important; }
  .text.-carniceria {
    color: #FF0057;
    font-family: "Noto Serif", serif; }
    .text.-carniceria:hover {
      font-weight: 900; }

html * {
  cursor: none !important; }

video {
  cursor: none !important; }

html,
body {
  overflow-x: hidden;
  cursor: none !important !important; }
  html .ljmdlk,
  html .kqCone,
  body .ljmdlk,
  body .kqCone {
    min-height: 100vh;
    overflow-y: auto; }
  html a,
  body a {
    cursor: none !important;
    text-decoration: none;
    color: #000; }
    html a:hover,
    body a:hover {
      cursor: none !important; }

.black-circle {
  cursor: none !important; }

.body {
  font-family: "swis721_blkex_btblack";
  overflow-x: hidden;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden; }
  .body:after {
    display: none;
    content: ''; }
  .body a {
    cursor: none !important;
    text-decoration: none;
    color: #000; }
    .body a:hover {
      cursor: none !important; }
  .body ul {
    -webkit-padding-start: 0;
            padding-inline-start: 0;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0; }
  .body h1,
  .body h2,
  .body h3,
  .body h4,
  .body h5,
  .body h6 {
    margin: 0; }
  .body .background-modal {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh; }
  .body .modal {
    padding: 20px;
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 11; }
    .body .modal.-video {
      padding: 0;
      width: calc(100% - 100px);
      height: calc(100% - 100px); }
  .body .close-modal-button {
    display: inline-block;
    position: fixed;
    z-index: 12;
    top: 20px;
    left: 50%;
    cursor: url(https://i.ibb.co/GPTVjWJ/add-3.png) 32 32, auto;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0); }
    .body .close-modal-button img {
      width: 30px; }

/** @define FlexEmbed */
/**
 * Flexible media embeds
 *
 * For use with media embeds – such as videos, slideshows, or even images –
 * that need to retain a specific aspect ratio but adapt to the width of their
 * containing element.
 *
 * Based on: http://alistapart.com/article/creating-intrinsic-ratios-for-video
 */
.FlexEmbed {
  display: block;
  overflow: hidden;
  position: relative; }

/**
 * The aspect-ratio hack is applied to an empty element because it allows
 * the component to respect `max-height`. Default aspect ratio is 1:1.
 */
.FlexEmbed-ratio {
  display: block;
  padding-bottom: 100%;
  width: 100%; }

/**
 * Modifier: 3:1 aspect ratio
 */
.FlexEmbed-ratio--3by1 {
  padding-bottom: calc(100% / 3); }

/**
 * Modifier: 2:1 aspect ratio
 */
.FlexEmbed-ratio--2by1 {
  padding-bottom: 50%; }

/**
 * Modifier: 16:9 aspect ratio
 */
.FlexEmbed-ratio--16by9 {
  padding-bottom: 56.25%; }

/**
 * Modifier: 4:3 aspect ratio
 */
.FlexEmbed-ratio--4by3 {
  padding-bottom: 75%; }

/**
 * Fit the content to the aspect ratio
 */
.FlexEmbed-content {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }

.l-work {
  position: relative;
  cursor: url(https://i.ibb.co/cLXqdFQ/output-onlinepngtools-2.png) 21 21, auto; }
  .l-work:scrollbar {
    display: none; }
  .l-work a:hover {
    cursor: url(https://i.ibb.co/cLXqdFQ/output-onlinepngtools-2.png) 21 21, auto; }
  .l-work__back-loader {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column; }
    .l-work__back-loader__bar {
      margin-top: 14px;
      height: 6px;
      width: 300px;
      -webkit-transition: 0.1s linear;
      transition: 0.1s linear; }
      .l-work__back-loader__bar__loader {
        height: 6px;
        background-color: #000; }
  .l-work__button-filters {
    position: fixed;
    display: none;
    align-content: center;
    align-items: center;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.8);
    top: 100px;
    right: 50px;
    padding: 10px;
    z-index: 8; }
    .l-work__button-filters.-clear {
      right: 160px; }
    @media (min-width: 768px) {
      .l-work__button-filters {
        display: flex; } }
    .l-work__button-filters__image {
      width: 15px;
      margin-right: 10px; }
  .l-work__project {
    display: inline-block;
    width: 100%;
    height: 300px;
    margin-top: -4px;
    background-repeat: no-repeat;
    background-size: cover; }
  .l-work .f20 {
    width: 10%;
    flex-basis: 10%;
    font-family: "swis721_blkex_btblack" !important;
    font-size: 12px !important;
    align-self: center;
    color: white;
    padding: 10px; }
  .l-work__filter-container {
    display: flex;
    right: auto;
    right: initial;
    width: 90%;
    flex-basis: 90%;
    flex-direction: row;
    align-items: flex-end;
    /* width: calc(100% - 120px); */
    /* -webkit-transform: translate(-50%, 0); */
    /* transform: translate(-50%, 0); */
    z-index: 10;
    mix-blend-mode: normal !important; }
    .l-work__filter-container__title {
      padding: 5px;
      width: calc(100% / 3);
      margin-left: 0;
      text-align: center;
      color: white !important;
      font-family: "swis721_blkex_btblack" !important;
      text-decoration: none;
      font-size: 16px !important; }
      @media (min-width: 768px) {
        .l-work__filter-container__title {
          font-size: 24px; } }
      .l-work__filter-container__title:last-of-type {
        margin-right: 0; }
      .l-work__filter-container__title.-selectedFilter {
        font-weight: bold; }
    .l-work__filter-container__advanced-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      left: 0;
      width: 100%;
      color: white !important; }
      @media (min-width: 768px) {
        .l-work__filter-container__advanced-container {
          bottom: 0; } }
      .l-work__filter-container__advanced-container__options-container {
        display: flex;
        align-items: flex-end;
        left: 0;
        flex-wrap: wrap;
        flex-direction: column; }
        @media (min-width: 768px) {
          .l-work__filter-container__advanced-container__options-container {
            bottom: 0; } }
      .l-work__filter-container__advanced-container__options {
        margin-bottom: 5px;
        padding: 6px 10px;
        width: calc(100vw - 14px); }
        @media (min-width: 768px) {
          .l-work__filter-container__advanced-container__options {
            margin-bottom: 0; } }
        .l-work__filter-container__advanced-container__options__show-more {
          display: flex;
          justify-content: flex-end;
          margin-top: 5px;
          font-size: 12px; }
        .l-work__filter-container__advanced-container__options__title {
          display: block;
          margin-bottom: 10px;
          display: block; }
          @media (min-width: 768px) {
            .l-work__filter-container__advanced-container__options__title {
              display: none; } }
        .l-work__filter-container__advanced-container__options__container-items {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          padding: 0;
          display: flex;
          justify-content: center; }
          .l-work__filter-container__advanced-container__options__container-items__item {
            width: auto;
            margin-right: 30px;
            text-align: right;
            display: block; }
            @media (min-width: 768px) {
              .l-work__filter-container__advanced-container__options__container-items__item {
                display: inline-block; } }
            .l-work__filter-container__advanced-container__options__container-items__item:last-of-type {
              margin-right: 0; }
            .l-work__filter-container__advanced-container__options__container-items__item__triangle {
              width: 10px; }
            .l-work__filter-container__advanced-container__options__container-items__item__count {
              display: flex;
              flex-direction: row;
              align-content: center;
              align-items: baseline; }
              .l-work__filter-container__advanced-container__options__container-items__item__count.selected {
                text-decoration: underline; }
              .l-work__filter-container__advanced-container__options__container-items__item__count div {
                margin-left: 5px;
                font-size: 14px; }
      .l-work__filter-container__advanced-container__titles-container {
        display: flex;
        position: relative;
        flex-direction: row;
        margin-bottom: 0;
        padding: 5px;
        width: calc(100vw - 10px); }
      .l-work__filter-container__advanced-container__item {
        margin-left: 0;
        width: calc(100% / 3);
        text-align: center; }
        .l-work__filter-container__advanced-container__item:hover {
          font-weight: bolder; }
        .l-work__filter-container__advanced-container__item:first-of-type {
          margin-left: 0; }
  .l-work .imageloader .FlexEmbed-ratio {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%; }
    .l-work .imageloader .FlexEmbed-ratio.-selected {
      display: none;
      opacity: 0.3;
      -moz-filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
      -o-filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
      -webkit-filter: grayscale(100%);
      filter: gray; }
  .l-work .imageloader .image-loader-container {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    text-align: center; }
  .l-work .imageloader .image-loader {
    width: 30px; }

.close {
  font-weight: bold !important;
  font-family: "Raleway", sans-serif !important; }

.filter-count {
  font-weight: bold !important;
  font-family: "Raleway", sans-serif !important; }

.filters-wrapper {
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 0px;
  width: 100%;
  flex-basis: 100%;
  height: 32px;
  z-index: 1; }

.project-grid {
  display: inline-block;
  position: relative;
  overflow: hidden;
  position: relative;
  width: calc(100% / 1);
  margin-top: -5px; }
  @media (min-width: 560px) {
    .project-grid {
      width: calc(100% / 1); } }
  @media (min-width: 720px) {
    .project-grid {
      width: calc(100% / 2); } }
  @media (min-width: 960px) {
    .project-grid {
      width: calc(100% / 3); } }
  @media (min-width: 1140px) {
    .project-grid {
      width: calc(100% / 4); } }

@charset "UTF-8";
@font-face {
  font-family: 'swis721_blkex_btblack';
  src: url(/static/media/swissek-webfont.0e33060b.woff2) format("woff2"), url(/static/media/swissek-webfont.9b53eabe.woff) format("woff");
  font-weight: normal;
  font-style: normal; }

.text {
  font-family: "swis721_blkex_btblack";
  text-decoration: none; }
  .text.-secondary {
    font-family: "Raleway", sans-serif; }
  .text.-uppercase {
    text-transform: uppercase; }
  .text.-center {
    text-align: center; }
  .text.-left {
    text-align: left; }
  .text.-bold {
    font-weight: 900; }
  .text.-light {
    font-weight: 300; }
  .text.-light-hover {
    font-weight: 300; }
    .text.-light-hover:hover {
      font-weight: 600; }
  .text.-shadow {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4); }
  .text.-black {
    color: #000; }
  .text.-white {
    color: #FFF; }
  .text.-xxl {
    font-size: 30px; }
    @media (min-width: 768px) {
      .text.-xxl {
        font-size: 34px; } }
  .text.-xl {
    font-size: 22px; }
    @media (min-width: 768px) {
      .text.-xl {
        font-size: 24px; } }
  .text.-l {
    font-size: 18px; }
    @media (min-width: 768px) {
      .text.-l {
        font-size: 22px; } }
  .text.-s {
    font-size: 10px; }
    @media (min-width: 768px) {
      .text.-s {
        font-size: 16px; } }
  .text.-xs {
    font-size: 12px; }
    @media (min-width: 768px) {
      .text.-xs {
        font-size: 16px; } }
  .text.-xxs {
    font-size: 10px; }
    @media (min-width: 768px) {
      .text.-xxs {
        font-size: 12px; } }
  .text.-xsm {
    font-size: 12px; }
    @media (min-width: 768px) {
      .text.-xsm {
        font-size: 14px; } }
  .text.-pointer {
    cursor: none !important; }
  .text.-carniceria {
    color: #FF0057;
    font-family: "Noto Serif", serif; }
    .text.-carniceria:hover {
      font-weight: 900; }

html * {
  cursor: none !important; }

video {
  cursor: none !important; }

html,
body {
  overflow-x: hidden;
  cursor: none !important !important; }
  html .ljmdlk,
  html .kqCone,
  body .ljmdlk,
  body .kqCone {
    min-height: 100vh;
    overflow-y: auto; }
  html a,
  body a {
    cursor: none !important;
    text-decoration: none;
    color: #000; }
    html a:hover,
    body a:hover {
      cursor: none !important; }

.black-circle {
  cursor: none !important; }

.body {
  font-family: "swis721_blkex_btblack";
  overflow-x: hidden;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden; }
  .body:after {
    display: none;
    content: ''; }
  .body a {
    cursor: none !important;
    text-decoration: none;
    color: #000; }
    .body a:hover {
      cursor: none !important; }
  .body ul {
    -webkit-padding-start: 0;
            padding-inline-start: 0;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0; }
  .body h1,
  .body h2,
  .body h3,
  .body h4,
  .body h5,
  .body h6 {
    margin: 0; }
  .body .background-modal {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh; }
  .body .modal {
    padding: 20px;
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 11; }
    .body .modal.-video {
      padding: 0;
      width: calc(100% - 100px);
      height: calc(100% - 100px); }
  .body .close-modal-button {
    display: inline-block;
    position: fixed;
    z-index: 12;
    top: 20px;
    left: 50%;
    cursor: url(https://i.ibb.co/GPTVjWJ/add-3.png) 32 32, auto;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0); }
    .body .close-modal-button img {
      width: 30px; }

/** @define FlexEmbed */
/**
 * Flexible media embeds
 *
 * For use with media embeds – such as videos, slideshows, or even images –
 * that need to retain a specific aspect ratio but adapt to the width of their
 * containing element.
 *
 * Based on: http://alistapart.com/article/creating-intrinsic-ratios-for-video
 */
.FlexEmbed {
  display: block;
  overflow: hidden;
  position: relative; }

/**
 * The aspect-ratio hack is applied to an empty element because it allows
 * the component to respect `max-height`. Default aspect ratio is 1:1.
 */
.FlexEmbed-ratio {
  display: block;
  padding-bottom: 100%;
  width: 100%; }

/**
 * Modifier: 3:1 aspect ratio
 */
.FlexEmbed-ratio--3by1 {
  padding-bottom: calc(100% / 3); }

/**
 * Modifier: 2:1 aspect ratio
 */
.FlexEmbed-ratio--2by1 {
  padding-bottom: 50%; }

/**
 * Modifier: 16:9 aspect ratio
 */
.FlexEmbed-ratio--16by9 {
  padding-bottom: 56.25%; }

/**
 * Modifier: 4:3 aspect ratio
 */
.FlexEmbed-ratio--4by3 {
  padding-bottom: 75%; }

/**
 * Fit the content to the aspect ratio
 */
.FlexEmbed-content {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }

.c-project__title {
  position: absolute;
  width: 80%;
  text-align: left;
  line-height: 1.6;
  display: none;
  z-index: 2;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0); }
  .c-project__title p {
    margin-top: 2px;
    margin-bottom: 0 !important; }

.c-project::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0; }

.c-project:hover {
  cursor: none !important; }
  .c-project:hover .c-project__title {
    display: block; }
  .c-project:hover::before {
    opacity: 1; }

.filter-bar {
  width: 100%;
  display: flex;
  flex-basis: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  text-transform: lowercase;
  color: white !important;
  font-family: "Raleway", sans-serif !important; }
  @media (min-width: 768px) {
    .filter-bar {
      font-size: 15px;
      padding-left: 60px;
      padding-right: 60px; } }

.filter-buttons {
  padding-right: 15px; }
  @media (min-width: 768px) {
    .filter-buttons {
      padding-right: 40px; } }
  .filter-buttons .filter-b {
    padding-left: 5px; }
    @media (min-width: 768px) {
      .filter-buttons .filter-b {
        padding-left: 10px; } }
    .filter-buttons .filter-b:hover {
      font-weight: bolder; }

.filter-button-more {
  margin-left: 15px; }
  .filter-button-more .filter-more:hover {
    font-weight: bolder; }

.filter-count {
  font-weight: bold;
  white-space: nowrap; }

.filters-current {
  display: flex;
  max-width: 80vw;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  overflow-x: hidden;
  background-attachment: local, local, scroll, scroll; }

.filter-one {
  padding-left: 15px;
  white-space: nowrap; }
  @media (min-width: 768px) {
    .filter-one {
      padding-left: 40px; } }
  .filter-one.date {
    padding-left: 15px; }
  .filter-one.selected {
    font-weight: bold; }
  .filter-one:hover {
    font-weight: bolder; }

.filter-tooltip {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  height: 25px;
  top: -40px;
  right: 350px;
  padding: 5px 15px 0px 15px;
  width: 60px;
  font-family: 'swis721_blkex_btblack', sans-serif; }
  @media (max-width: 768px) {
    .filter-tooltip {
      display: none; } }
  .filter-tooltip.hidden {
    opacity: 0;
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s; }

.filter-tooltip:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-width: 7px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent;
  left: 36px;
  top: 30px; }

@charset "UTF-8";
@font-face {
  font-family: 'swis721_blkex_btblack';
  src: url(/static/media/swissek-webfont.0e33060b.woff2) format("woff2"), url(/static/media/swissek-webfont.9b53eabe.woff) format("woff");
  font-weight: normal;
  font-style: normal; }

.text {
  font-family: "swis721_blkex_btblack";
  text-decoration: none; }
  .text.-secondary {
    font-family: "Raleway", sans-serif; }
  .text.-uppercase {
    text-transform: uppercase; }
  .text.-center {
    text-align: center; }
  .text.-left {
    text-align: left; }
  .text.-bold {
    font-weight: 900; }
  .text.-light {
    font-weight: 300; }
  .text.-light-hover {
    font-weight: 300; }
    .text.-light-hover:hover {
      font-weight: 600; }
  .text.-shadow {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4); }
  .text.-black {
    color: #000; }
  .text.-white {
    color: #FFF; }
  .text.-xxl {
    font-size: 30px; }
    @media (min-width: 768px) {
      .text.-xxl {
        font-size: 34px; } }
  .text.-xl {
    font-size: 22px; }
    @media (min-width: 768px) {
      .text.-xl {
        font-size: 24px; } }
  .text.-l {
    font-size: 18px; }
    @media (min-width: 768px) {
      .text.-l {
        font-size: 22px; } }
  .text.-s {
    font-size: 10px; }
    @media (min-width: 768px) {
      .text.-s {
        font-size: 16px; } }
  .text.-xs {
    font-size: 12px; }
    @media (min-width: 768px) {
      .text.-xs {
        font-size: 16px; } }
  .text.-xxs {
    font-size: 10px; }
    @media (min-width: 768px) {
      .text.-xxs {
        font-size: 12px; } }
  .text.-xsm {
    font-size: 12px; }
    @media (min-width: 768px) {
      .text.-xsm {
        font-size: 14px; } }
  .text.-pointer {
    cursor: none !important; }
  .text.-carniceria {
    color: #FF0057;
    font-family: "Noto Serif", serif; }
    .text.-carniceria:hover {
      font-weight: 900; }

html * {
  cursor: none !important; }

video {
  cursor: none !important; }

html,
body {
  overflow-x: hidden;
  cursor: none !important !important; }
  html .ljmdlk,
  html .kqCone,
  body .ljmdlk,
  body .kqCone {
    min-height: 100vh;
    overflow-y: auto; }
  html a,
  body a {
    cursor: none !important;
    text-decoration: none;
    color: #000; }
    html a:hover,
    body a:hover {
      cursor: none !important; }

.black-circle {
  cursor: none !important; }

.body {
  font-family: "swis721_blkex_btblack";
  overflow-x: hidden;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden; }
  .body:after {
    display: none;
    content: ''; }
  .body a {
    cursor: none !important;
    text-decoration: none;
    color: #000; }
    .body a:hover {
      cursor: none !important; }
  .body ul {
    -webkit-padding-start: 0;
            padding-inline-start: 0;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0; }
  .body h1,
  .body h2,
  .body h3,
  .body h4,
  .body h5,
  .body h6 {
    margin: 0; }
  .body .background-modal {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh; }
  .body .modal {
    padding: 20px;
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 11; }
    .body .modal.-video {
      padding: 0;
      width: calc(100% - 100px);
      height: calc(100% - 100px); }
  .body .close-modal-button {
    display: inline-block;
    position: fixed;
    z-index: 12;
    top: 20px;
    left: 50%;
    cursor: url(https://i.ibb.co/GPTVjWJ/add-3.png) 32 32, auto;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0); }
    .body .close-modal-button img {
      width: 30px; }

/** @define FlexEmbed */
/**
 * Flexible media embeds
 *
 * For use with media embeds – such as videos, slideshows, or even images –
 * that need to retain a specific aspect ratio but adapt to the width of their
 * containing element.
 *
 * Based on: http://alistapart.com/article/creating-intrinsic-ratios-for-video
 */
.FlexEmbed {
  display: block;
  overflow: hidden;
  position: relative; }

/**
 * The aspect-ratio hack is applied to an empty element because it allows
 * the component to respect `max-height`. Default aspect ratio is 1:1.
 */
.FlexEmbed-ratio {
  display: block;
  padding-bottom: 100%;
  width: 100%; }

/**
 * Modifier: 3:1 aspect ratio
 */
.FlexEmbed-ratio--3by1 {
  padding-bottom: calc(100% / 3); }

/**
 * Modifier: 2:1 aspect ratio
 */
.FlexEmbed-ratio--2by1 {
  padding-bottom: 50%; }

/**
 * Modifier: 16:9 aspect ratio
 */
.FlexEmbed-ratio--16by9 {
  padding-bottom: 56.25%; }

/**
 * Modifier: 4:3 aspect ratio
 */
.FlexEmbed-ratio--4by3 {
  padding-bottom: 75%; }

/**
 * Fit the content to the aspect ratio
 */
.FlexEmbed-content {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }

.l-what {
  position: relative;
  width: 100vw;
  height: 100vh; }
  .l-what .text {
    font-size: 30px; }
  .l-what__searcher {
    position: fixed;
    bottom: 20px;
    padding: 0 20px;
    width: calc(100vw - 60px); }
    @media (min-width: 768px) {
      .l-what__searcher {
        position: absolute;
        width: auto; } }
    .l-what__searcher h1 {
      font-size: 14px !important; }
      @media (min-width: 768px) {
        .l-what__searcher h1 {
          font-size: 18px !important; } }
    @media (min-width: 768px) {
      .l-what__searcher {
        right: 280px;
        bottom: 80px;
        padding: auto; } }
    .l-what__searcher__input-container {
      position: relative; }
      .l-what__searcher__input-container img {
        position: absolute;
        top: 50%;
        left: 0;
        width: 30px;
        -webkit-transform: translate(0%, -50%);
                transform: translate(0%, -50%); }
      .l-what__searcher__input-container__input {
        width: 100%;
        margin-top: 10px;
        border: 0;
        border-bottom: 2px solid #000;
        outline: 0;
        font-size: 28px;
        text-align: right; }
  .l-what .tag-cloud-mobile {
    display: flex;
    flex-direction: column;
    width: calc(100vw - 30px);
    height: calc(100vh - 260px);
    position: absolute;
    top: 110px;
    overflow-y: scroll;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0); }
    .l-what .tag-cloud-mobile a {
      font-size: 16px;
      margin-bottom: 5px; }

@charset "UTF-8";
@font-face {
  font-family: 'swis721_blkex_btblack';
  src: url(/static/media/swissek-webfont.0e33060b.woff2) format("woff2"), url(/static/media/swissek-webfont.9b53eabe.woff) format("woff");
  font-weight: normal;
  font-style: normal; }

.text {
  font-family: "swis721_blkex_btblack";
  text-decoration: none; }
  .text.-secondary {
    font-family: "Raleway", sans-serif; }
  .text.-uppercase {
    text-transform: uppercase; }
  .text.-center {
    text-align: center; }
  .text.-left {
    text-align: left; }
  .text.-bold {
    font-weight: 900; }
  .text.-light {
    font-weight: 300; }
  .text.-light-hover {
    font-weight: 300; }
    .text.-light-hover:hover {
      font-weight: 600; }
  .text.-shadow {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4); }
  .text.-black {
    color: #000; }
  .text.-white {
    color: #FFF; }
  .text.-xxl {
    font-size: 30px; }
    @media (min-width: 768px) {
      .text.-xxl {
        font-size: 34px; } }
  .text.-xl {
    font-size: 22px; }
    @media (min-width: 768px) {
      .text.-xl {
        font-size: 24px; } }
  .text.-l {
    font-size: 18px; }
    @media (min-width: 768px) {
      .text.-l {
        font-size: 22px; } }
  .text.-s {
    font-size: 10px; }
    @media (min-width: 768px) {
      .text.-s {
        font-size: 16px; } }
  .text.-xs {
    font-size: 12px; }
    @media (min-width: 768px) {
      .text.-xs {
        font-size: 16px; } }
  .text.-xxs {
    font-size: 10px; }
    @media (min-width: 768px) {
      .text.-xxs {
        font-size: 12px; } }
  .text.-xsm {
    font-size: 12px; }
    @media (min-width: 768px) {
      .text.-xsm {
        font-size: 14px; } }
  .text.-pointer {
    cursor: none !important; }
  .text.-carniceria {
    color: #FF0057;
    font-family: "Noto Serif", serif; }
    .text.-carniceria:hover {
      font-weight: 900; }

html * {
  cursor: none !important; }

video {
  cursor: none !important; }

html,
body {
  overflow-x: hidden;
  cursor: none !important !important; }
  html .ljmdlk,
  html .kqCone,
  body .ljmdlk,
  body .kqCone {
    min-height: 100vh;
    overflow-y: auto; }
  html a,
  body a {
    cursor: none !important;
    text-decoration: none;
    color: #000; }
    html a:hover,
    body a:hover {
      cursor: none !important; }

.black-circle {
  cursor: none !important; }

.body {
  font-family: "swis721_blkex_btblack";
  overflow-x: hidden;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden; }
  .body:after {
    display: none;
    content: ''; }
  .body a {
    cursor: none !important;
    text-decoration: none;
    color: #000; }
    .body a:hover {
      cursor: none !important; }
  .body ul {
    -webkit-padding-start: 0;
            padding-inline-start: 0;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0; }
  .body h1,
  .body h2,
  .body h3,
  .body h4,
  .body h5,
  .body h6 {
    margin: 0; }
  .body .background-modal {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh; }
  .body .modal {
    padding: 20px;
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 11; }
    .body .modal.-video {
      padding: 0;
      width: calc(100% - 100px);
      height: calc(100% - 100px); }
  .body .close-modal-button {
    display: inline-block;
    position: fixed;
    z-index: 12;
    top: 20px;
    left: 50%;
    cursor: url(https://i.ibb.co/GPTVjWJ/add-3.png) 32 32, auto;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0); }
    .body .close-modal-button img {
      width: 30px; }

/** @define FlexEmbed */
/**
 * Flexible media embeds
 *
 * For use with media embeds – such as videos, slideshows, or even images –
 * that need to retain a specific aspect ratio but adapt to the width of their
 * containing element.
 *
 * Based on: http://alistapart.com/article/creating-intrinsic-ratios-for-video
 */
.FlexEmbed {
  display: block;
  overflow: hidden;
  position: relative; }

/**
 * The aspect-ratio hack is applied to an empty element because it allows
 * the component to respect `max-height`. Default aspect ratio is 1:1.
 */
.FlexEmbed-ratio {
  display: block;
  padding-bottom: 100%;
  width: 100%; }

/**
 * Modifier: 3:1 aspect ratio
 */
.FlexEmbed-ratio--3by1 {
  padding-bottom: calc(100% / 3); }

/**
 * Modifier: 2:1 aspect ratio
 */
.FlexEmbed-ratio--2by1 {
  padding-bottom: 50%; }

/**
 * Modifier: 16:9 aspect ratio
 */
.FlexEmbed-ratio--16by9 {
  padding-bottom: 56.25%; }

/**
 * Modifier: 4:3 aspect ratio
 */
.FlexEmbed-ratio--4by3 {
  padding-bottom: 75%; }

/**
 * Fit the content to the aspect ratio
 */
.FlexEmbed-content {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }

.l-office {
  position: relative;
  overflow: hidden;
  cursor: none !important; }
  .l-office a {
    cursor: none !important; }
    .l-office a:hover {
      cursor: none !important; }
  .l-office__image-section {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 40%;
    height: 40%;
    background-size: contain;
    background-color: transparent;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    position: absolute; }
    @media (min-width: 768px) {
      .l-office__image-section {
        background-size: cover; } }
  .l-office__content {
    position: relative;
    width: calc(100% - 50px);
    margin: 110px auto;
    padding-right: 0%;
    z-index: 10; }
    .l-office__content__carniceria {
      text-align: right;
      display: none;
      margin-bottom: 80px; }
      @media (min-width: 768px) {
        .l-office__content__carniceria {
          display: block; } }
    @media (min-width: 768px) {
      .l-office__content {
        margin: 210px 0 0 30%;
        padding-right: 30%;
        width: calc(70% - 300px); } }
    .l-office__content__name {
      margin-top: 15px; }
    .l-office__content__section {
      margin-bottom: 50px; }
    .l-office__content__item-people {
      position: relative;
      margin-bottom: 60px;
      z-index: 20; }
      .l-office__content__item-people__name {
        margin-bottom: 10px; }
      .l-office__content__item-people__image {
        position: absolute;
        top: 50%;
        left: 60%;
        width: 60%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        opacity: 0; }
  .l-office__box-contact-links {
    position: fixed;
    top: 100px;
    left: 60px;
    z-index: 19;
    display: flex;
    bottom: 0;
    top: initial;
    right: 10px;
    left: initial; }
    @media (min-width: 768px) {
      .l-office__box-contact-links {
        display: block;
        max-width: 260px;
        top: 100px;
        left: 60px;
        bottom: auto;
        bottom: initial;
        right: auto;
        right: initial; } }
    .l-office__box-contact-links__container {
      margin-bottom: 15px; }
      .l-office__box-contact-links__container .email-image {
        display: block;
        margin-right: 14px; }
        @media (min-width: 768px) {
          .l-office__box-contact-links__container .email-image {
            display: none; } }
      .l-office__box-contact-links__container__email-text {
        display: none; }
        @media (min-width: 768px) {
          .l-office__box-contact-links__container__email-text {
            display: block;
            margin-right: 10px; } }
      .l-office__box-contact-links__container__icon {
        width: 25px; }

@charset "UTF-8";
@font-face {
  font-family: 'swis721_blkex_btblack';
  src: url(/static/media/swissek-webfont.0e33060b.woff2) format("woff2"), url(/static/media/swissek-webfont.9b53eabe.woff) format("woff");
  font-weight: normal;
  font-style: normal; }

.text {
  font-family: "swis721_blkex_btblack";
  text-decoration: none; }
  .text.-secondary {
    font-family: "Raleway", sans-serif; }
  .text.-uppercase {
    text-transform: uppercase; }
  .text.-center {
    text-align: center; }
  .text.-left {
    text-align: left; }
  .text.-bold {
    font-weight: 900; }
  .text.-light {
    font-weight: 300; }
  .text.-light-hover {
    font-weight: 300; }
    .text.-light-hover:hover {
      font-weight: 600; }
  .text.-shadow {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4); }
  .text.-black {
    color: #000; }
  .text.-white {
    color: #FFF; }
  .text.-xxl {
    font-size: 30px; }
    @media (min-width: 768px) {
      .text.-xxl {
        font-size: 34px; } }
  .text.-xl {
    font-size: 22px; }
    @media (min-width: 768px) {
      .text.-xl {
        font-size: 24px; } }
  .text.-l {
    font-size: 18px; }
    @media (min-width: 768px) {
      .text.-l {
        font-size: 22px; } }
  .text.-s {
    font-size: 10px; }
    @media (min-width: 768px) {
      .text.-s {
        font-size: 16px; } }
  .text.-xs {
    font-size: 12px; }
    @media (min-width: 768px) {
      .text.-xs {
        font-size: 16px; } }
  .text.-xxs {
    font-size: 10px; }
    @media (min-width: 768px) {
      .text.-xxs {
        font-size: 12px; } }
  .text.-xsm {
    font-size: 12px; }
    @media (min-width: 768px) {
      .text.-xsm {
        font-size: 14px; } }
  .text.-pointer {
    cursor: none !important; }
  .text.-carniceria {
    color: #FF0057;
    font-family: "Noto Serif", serif; }
    .text.-carniceria:hover {
      font-weight: 900; }

html * {
  cursor: none !important; }

video {
  cursor: none !important; }

html,
body {
  overflow-x: hidden;
  cursor: none !important !important; }
  html .ljmdlk,
  html .kqCone,
  body .ljmdlk,
  body .kqCone {
    min-height: 100vh;
    overflow-y: auto; }
  html a,
  body a {
    cursor: none !important;
    text-decoration: none;
    color: #000; }
    html a:hover,
    body a:hover {
      cursor: none !important; }

.black-circle {
  cursor: none !important; }

.body {
  font-family: "swis721_blkex_btblack";
  overflow-x: hidden;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden; }
  .body:after {
    display: none;
    content: ''; }
  .body a {
    cursor: none !important;
    text-decoration: none;
    color: #000; }
    .body a:hover {
      cursor: none !important; }
  .body ul {
    -webkit-padding-start: 0;
            padding-inline-start: 0;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0; }
  .body h1,
  .body h2,
  .body h3,
  .body h4,
  .body h5,
  .body h6 {
    margin: 0; }
  .body .background-modal {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh; }
  .body .modal {
    padding: 20px;
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 11; }
    .body .modal.-video {
      padding: 0;
      width: calc(100% - 100px);
      height: calc(100% - 100px); }
  .body .close-modal-button {
    display: inline-block;
    position: fixed;
    z-index: 12;
    top: 20px;
    left: 50%;
    cursor: url(https://i.ibb.co/GPTVjWJ/add-3.png) 32 32, auto;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0); }
    .body .close-modal-button img {
      width: 30px; }

/** @define FlexEmbed */
/**
 * Flexible media embeds
 *
 * For use with media embeds – such as videos, slideshows, or even images –
 * that need to retain a specific aspect ratio but adapt to the width of their
 * containing element.
 *
 * Based on: http://alistapart.com/article/creating-intrinsic-ratios-for-video
 */
.FlexEmbed {
  display: block;
  overflow: hidden;
  position: relative; }

/**
 * The aspect-ratio hack is applied to an empty element because it allows
 * the component to respect `max-height`. Default aspect ratio is 1:1.
 */
.FlexEmbed-ratio {
  display: block;
  padding-bottom: 100%;
  width: 100%; }

/**
 * Modifier: 3:1 aspect ratio
 */
.FlexEmbed-ratio--3by1 {
  padding-bottom: calc(100% / 3); }

/**
 * Modifier: 2:1 aspect ratio
 */
.FlexEmbed-ratio--2by1 {
  padding-bottom: 50%; }

/**
 * Modifier: 16:9 aspect ratio
 */
.FlexEmbed-ratio--16by9 {
  padding-bottom: 56.25%; }

/**
 * Modifier: 4:3 aspect ratio
 */
.FlexEmbed-ratio--4by3 {
  padding-bottom: 75%; }

/**
 * Fit the content to the aspect ratio
 */
.FlexEmbed-content {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }

.l-news {
  position: relative;
  width: calc(100% - 30px);
  margin: 80px auto; }
  @media (min-width: 768px) {
    .l-news {
      width: 50%;
      margin: 200px auto; } }
  .l-news__grid {
    position: relative;
    z-index: 3; }
  .l-news__content {
    display: block;
    margin-bottom: 30px; }
    @media (min-width: 768px) {
      .l-news__content {
        font-size: medium;
        font-size: initial;
        margin-bottom: 80px; } }
    .l-news__content__title {
      margin-top: 10px;
      font-size: 18px !important; }
      @media (min-width: 768px) {
        .l-news__content__title {
          font-size: 34px !important; } }
  .l-news__pic {
    position: fixed;
    width: 40%;
    height: 40%;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 2; }

.l-new__pic {
  width: 80%;
  z-index: -2;
  position: absolute;
  display: none;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.new-wrapper {
  position: relative; }
  .new-wrapper:hover img {
    display: block; }

@charset "UTF-8";
@font-face {
  font-family: 'swis721_blkex_btblack';
  src: url(/static/media/swissek-webfont.0e33060b.woff2) format("woff2"), url(/static/media/swissek-webfont.9b53eabe.woff) format("woff");
  font-weight: normal;
  font-style: normal; }

.text {
  font-family: "swis721_blkex_btblack";
  text-decoration: none; }
  .text.-secondary {
    font-family: "Raleway", sans-serif; }
  .text.-uppercase {
    text-transform: uppercase; }
  .text.-center {
    text-align: center; }
  .text.-left {
    text-align: left; }
  .text.-bold {
    font-weight: 900; }
  .text.-light {
    font-weight: 300; }
  .text.-light-hover {
    font-weight: 300; }
    .text.-light-hover:hover {
      font-weight: 600; }
  .text.-shadow {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4); }
  .text.-black {
    color: #000; }
  .text.-white {
    color: #FFF; }
  .text.-xxl {
    font-size: 30px; }
    @media (min-width: 768px) {
      .text.-xxl {
        font-size: 34px; } }
  .text.-xl {
    font-size: 22px; }
    @media (min-width: 768px) {
      .text.-xl {
        font-size: 24px; } }
  .text.-l {
    font-size: 18px; }
    @media (min-width: 768px) {
      .text.-l {
        font-size: 22px; } }
  .text.-s {
    font-size: 10px; }
    @media (min-width: 768px) {
      .text.-s {
        font-size: 16px; } }
  .text.-xs {
    font-size: 12px; }
    @media (min-width: 768px) {
      .text.-xs {
        font-size: 16px; } }
  .text.-xxs {
    font-size: 10px; }
    @media (min-width: 768px) {
      .text.-xxs {
        font-size: 12px; } }
  .text.-xsm {
    font-size: 12px; }
    @media (min-width: 768px) {
      .text.-xsm {
        font-size: 14px; } }
  .text.-pointer {
    cursor: none !important; }
  .text.-carniceria {
    color: #FF0057;
    font-family: "Noto Serif", serif; }
    .text.-carniceria:hover {
      font-weight: 900; }

html * {
  cursor: none !important; }

video {
  cursor: none !important; }

html,
body {
  overflow-x: hidden;
  cursor: none !important !important; }
  html .ljmdlk,
  html .kqCone,
  body .ljmdlk,
  body .kqCone {
    min-height: 100vh;
    overflow-y: auto; }
  html a,
  body a {
    cursor: none !important;
    text-decoration: none;
    color: #000; }
    html a:hover,
    body a:hover {
      cursor: none !important; }

.black-circle {
  cursor: none !important; }

.body {
  font-family: "swis721_blkex_btblack";
  overflow-x: hidden;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden; }
  .body:after {
    display: none;
    content: ''; }
  .body a {
    cursor: none !important;
    text-decoration: none;
    color: #000; }
    .body a:hover {
      cursor: none !important; }
  .body ul {
    -webkit-padding-start: 0;
            padding-inline-start: 0;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0; }
  .body h1,
  .body h2,
  .body h3,
  .body h4,
  .body h5,
  .body h6 {
    margin: 0; }
  .body .background-modal {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh; }
  .body .modal {
    padding: 20px;
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 11; }
    .body .modal.-video {
      padding: 0;
      width: calc(100% - 100px);
      height: calc(100% - 100px); }
  .body .close-modal-button {
    display: inline-block;
    position: fixed;
    z-index: 12;
    top: 20px;
    left: 50%;
    cursor: url(https://i.ibb.co/GPTVjWJ/add-3.png) 32 32, auto;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0); }
    .body .close-modal-button img {
      width: 30px; }

/** @define FlexEmbed */
/**
 * Flexible media embeds
 *
 * For use with media embeds – such as videos, slideshows, or even images –
 * that need to retain a specific aspect ratio but adapt to the width of their
 * containing element.
 *
 * Based on: http://alistapart.com/article/creating-intrinsic-ratios-for-video
 */
.FlexEmbed {
  display: block;
  overflow: hidden;
  position: relative; }

/**
 * The aspect-ratio hack is applied to an empty element because it allows
 * the component to respect `max-height`. Default aspect ratio is 1:1.
 */
.FlexEmbed-ratio {
  display: block;
  padding-bottom: 100%;
  width: 100%; }

/**
 * Modifier: 3:1 aspect ratio
 */
.FlexEmbed-ratio--3by1 {
  padding-bottom: calc(100% / 3); }

/**
 * Modifier: 2:1 aspect ratio
 */
.FlexEmbed-ratio--2by1 {
  padding-bottom: 50%; }

/**
 * Modifier: 16:9 aspect ratio
 */
.FlexEmbed-ratio--16by9 {
  padding-bottom: 56.25%; }

/**
 * Modifier: 4:3 aspect ratio
 */
.FlexEmbed-ratio--4by3 {
  padding-bottom: 75%; }

/**
 * Fit the content to the aspect ratio
 */
.FlexEmbed-content {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }

.l-project-detail {
  position: relative; }
  .l-project-detail__grid-mobile {
    display: block; }
    @media (min-width: 768px) {
      .l-project-detail__grid-mobile {
        display: none; } }
    .l-project-detail__grid-mobile__image {
      width: 100%;
      margin-bottom: 10px; }
      .l-project-detail__grid-mobile__image:last-of-type {
        margin-bottom: 0; }
  .l-project-detail .slick-dots {
    display: flex;
    position: relative;
    top: -110px;
    align-content: center;
    align-items: center;
    justify-content: center;
    margin-left: 0;
    text-align: center; }
    .l-project-detail .slick-dots li {
      display: inline-block;
      width: 12px;
      height: 12px;
      margin-right: 20px;
      padding: 0;
      -webkit-transition: .1s linear;
      transition: .1s linear;
      border: 3px solid #FFF;
      border-radius: 100%;
      background-color: transparent;
      cursor: none !important;
      box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.3); }
      .l-project-detail .slick-dots li.-selected {
        background-color: #FFF; }
  .l-project-detail .slick-slider {
    max-height: 100vh;
    display: none; }
    @media (min-width: 768px) {
      .l-project-detail .slick-slider {
        display: block; } }
  .l-project-detail__triangle-left {
    position: absolute;
    left: 60px;
    top: 50%;
    -webkit-transform: translate(0, -50%) rotate(-90deg);
            transform: translate(0, -50%) rotate(-90deg);
    z-index: 10;
    width: 60px;
    display: none; }
    @media (min-width: 768px) {
      .l-project-detail__triangle-left {
        display: block; } }
  .l-project-detail__triangle-right {
    position: absolute;
    right: 60px;
    top: 50%;
    -webkit-transform: translate(0, -50%) rotate(90deg);
            transform: translate(0, -50%) rotate(90deg);
    z-index: 10;
    width: 60px;
    display: none; }
    @media (min-width: 768px) {
      .l-project-detail__triangle-right {
        display: block; } }
  .l-project-detail__accordion {
    background-color: rgba(255, 255, 255, 0.8);
    height: 100%;
    z-index: 10;
    overflow-y: scroll;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    overflow: auto; }
    .l-project-detail__accordion * {
      white-space: normal;
      word-break: break-word; }
    .l-project-detail__accordion.credit {
      left: 400px;
      top: 50%; }
      .l-project-detail__accordion.credit.-no-scroll {
        overflow: hidden; }
    .l-project-detail__accordion.abstract.-no-scroll {
      overflow: hidden; }
    .l-project-detail__accordion__title {
      display: flex;
      align-content: center;
      padding: 5px 10px; }
      .l-project-detail__accordion__title__icon {
        width: 12px;
        margin-right: 10px;
        -webkit-transition: .2s linear;
        transition: .2s linear; }
        .l-project-detail__accordion__title__icon.-open {
          -webkit-transform: rotate(45deg);
                  transform: rotate(45deg); }
    .l-project-detail__accordion__content {
      padding: 5px 10px;
      display: none; }
  .l-project-detail__triangle-left {
    position: absolute;
    cursor: none !important;
    left: 10px;
    top: 50%;
    -webkit-transform: translate(0, -50%) rotate(-90deg);
            transform: translate(0, -50%) rotate(-90deg);
    z-index: 10;
    width: 60px; }
  .l-project-detail__triangle-right {
    position: absolute;
    cursor: none !important;
    right: 10px;
    top: 50%;
    -webkit-transform: translate(0, -50%) rotate(90deg);
            transform: translate(0, -50%) rotate(90deg);
    z-index: 10;
    width: 60px; }
  .l-project-detail .credit-box-content-mobile {
    position: fixed;
    bottom: 0;
    width: 50%;
    left: 0;
    height: 30px;
    display: block; }
    @media (min-width: 768px) {
      .l-project-detail .credit-box-content-mobile {
        display: none; } }
  .l-project-detail .abstract-box-content-mobile {
    position: fixed;
    bottom: 0;
    width: 50%;
    right: 0;
    height: 30px;
    display: block; }
    @media (min-width: 768px) {
      .l-project-detail .abstract-box-content-mobile {
        display: none; } }
  .l-project-detail .box-markdown-mobile {
    position: fixed;
    width: 90%;
    height: 65%;
    overflow-y: scroll;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.8);
    top: 50%;
    left: 50%;
    padding: 10px; }

@charset "UTF-8";
@font-face {
  font-family: 'swis721_blkex_btblack';
  src: url(/static/media/swissek-webfont.0e33060b.woff2) format("woff2"), url(/static/media/swissek-webfont.9b53eabe.woff) format("woff");
  font-weight: normal;
  font-style: normal; }

.text {
  font-family: "swis721_blkex_btblack";
  text-decoration: none; }
  .text.-secondary {
    font-family: "Raleway", sans-serif; }
  .text.-uppercase {
    text-transform: uppercase; }
  .text.-center {
    text-align: center; }
  .text.-left {
    text-align: left; }
  .text.-bold {
    font-weight: 900; }
  .text.-light {
    font-weight: 300; }
  .text.-light-hover {
    font-weight: 300; }
    .text.-light-hover:hover {
      font-weight: 600; }
  .text.-shadow {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4); }
  .text.-black {
    color: #000; }
  .text.-white {
    color: #FFF; }
  .text.-xxl {
    font-size: 30px; }
    @media (min-width: 768px) {
      .text.-xxl {
        font-size: 34px; } }
  .text.-xl {
    font-size: 22px; }
    @media (min-width: 768px) {
      .text.-xl {
        font-size: 24px; } }
  .text.-l {
    font-size: 18px; }
    @media (min-width: 768px) {
      .text.-l {
        font-size: 22px; } }
  .text.-s {
    font-size: 10px; }
    @media (min-width: 768px) {
      .text.-s {
        font-size: 16px; } }
  .text.-xs {
    font-size: 12px; }
    @media (min-width: 768px) {
      .text.-xs {
        font-size: 16px; } }
  .text.-xxs {
    font-size: 10px; }
    @media (min-width: 768px) {
      .text.-xxs {
        font-size: 12px; } }
  .text.-xsm {
    font-size: 12px; }
    @media (min-width: 768px) {
      .text.-xsm {
        font-size: 14px; } }
  .text.-pointer {
    cursor: none !important; }
  .text.-carniceria {
    color: #FF0057;
    font-family: "Noto Serif", serif; }
    .text.-carniceria:hover {
      font-weight: 900; }

html * {
  cursor: none !important; }

video {
  cursor: none !important; }

html,
body {
  overflow-x: hidden;
  cursor: none !important !important; }
  html .ljmdlk,
  html .kqCone,
  body .ljmdlk,
  body .kqCone {
    min-height: 100vh;
    overflow-y: auto; }
  html a,
  body a {
    cursor: none !important;
    text-decoration: none;
    color: #000; }
    html a:hover,
    body a:hover {
      cursor: none !important; }

.black-circle {
  cursor: none !important; }

.body {
  font-family: "swis721_blkex_btblack";
  overflow-x: hidden;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden; }
  .body:after {
    display: none;
    content: ''; }
  .body a {
    cursor: none !important;
    text-decoration: none;
    color: #000; }
    .body a:hover {
      cursor: none !important; }
  .body ul {
    -webkit-padding-start: 0;
            padding-inline-start: 0;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0; }
  .body h1,
  .body h2,
  .body h3,
  .body h4,
  .body h5,
  .body h6 {
    margin: 0; }
  .body .background-modal {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh; }
  .body .modal {
    padding: 20px;
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 11; }
    .body .modal.-video {
      padding: 0;
      width: calc(100% - 100px);
      height: calc(100% - 100px); }
  .body .close-modal-button {
    display: inline-block;
    position: fixed;
    z-index: 12;
    top: 20px;
    left: 50%;
    cursor: url(https://i.ibb.co/GPTVjWJ/add-3.png) 32 32, auto;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0); }
    .body .close-modal-button img {
      width: 30px; }

/** @define FlexEmbed */
/**
 * Flexible media embeds
 *
 * For use with media embeds – such as videos, slideshows, or even images –
 * that need to retain a specific aspect ratio but adapt to the width of their
 * containing element.
 *
 * Based on: http://alistapart.com/article/creating-intrinsic-ratios-for-video
 */
.FlexEmbed {
  display: block;
  overflow: hidden;
  position: relative; }

/**
 * The aspect-ratio hack is applied to an empty element because it allows
 * the component to respect `max-height`. Default aspect ratio is 1:1.
 */
.FlexEmbed-ratio {
  display: block;
  padding-bottom: 100%;
  width: 100%; }

/**
 * Modifier: 3:1 aspect ratio
 */
.FlexEmbed-ratio--3by1 {
  padding-bottom: calc(100% / 3); }

/**
 * Modifier: 2:1 aspect ratio
 */
.FlexEmbed-ratio--2by1 {
  padding-bottom: 50%; }

/**
 * Modifier: 16:9 aspect ratio
 */
.FlexEmbed-ratio--16by9 {
  padding-bottom: 56.25%; }

/**
 * Modifier: 4:3 aspect ratio
 */
.FlexEmbed-ratio--4by3 {
  padding-bottom: 75%; }

/**
 * Fit the content to the aspect ratio
 */
.FlexEmbed-content {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }

.c-header {
  display: flex;
  position: fixed;
  top: -10px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  justify-content: space-between;
  width: calc(100% - 30px);
  z-index: 11;
  mix-blend-mode: exclusion !important; }
  .c-header.-black-circle {
    cursor: none !important; }
  @media (min-width: 768px) {
    .c-header {
      top: 30px;
      width: calc(100% - 120px); } }
  .c-header .hidden-mobile {
    display: none; }
    @media (min-width: 768px) {
      .c-header .hidden-mobile {
        display: block; } }
  .c-header__title {
    position: absolute;
    top: 30px;
    font-size: 14px !important; }
    @media (min-width: 768px) {
      .c-header__title {
        position: static;
        position: initial;
        font-size: 24px !important; } }

.c-header-mobile {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #FFF;
  z-index: 20; }
  .c-header-mobile__relative {
    display: flex;
    position: relative;
    width: 100vw;
    height: 100%;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column; }
    .c-header-mobile__relative div {
      margin-bottom: 30px; }
      .c-header-mobile__relative div:last-of-type {
        margin-bottom: 0; }
    .c-header-mobile__relative__footer {
      display: flex;
      position: absolute;
      bottom: 10px;
      width: 90%;
      left: calc(50% - 0px);
      -webkit-transform: translate(-50%, 0);
              transform: translate(-50%, 0);
      flex-direction: column;
      justify-content: space-between;
      font-size: 14px; }
      .c-header-mobile__relative__footer div {
        margin-bottom: 5px;
        padding: 5px; }
        .c-header-mobile__relative__footer div:first-of-type {
          text-align: left; }
        .c-header-mobile__relative__footer div:last-of-type {
          text-align: left; }
  .c-header-mobile.-show {
    display: flex; }
  .c-header-mobile.-hidden {
    display: none; }

.menu-text-header-item {
  color: black !important;
  text-shadow: -2px -2px 0 white, 2px -2px 0 white, -2px 2px 0 white, 2px 2px 0 white; }

.c-header-mobile-button {
  cursor: none !important;
  position: fixed;
  right: 0;
  top: 15px;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  z-index: 21;
  display: block; }
  @media (min-width: 768px) {
    .c-header-mobile-button {
      display: none; } }
  .c-header-mobile-button img {
    cursor: none !important;
    width: 30px; }

@charset "UTF-8";
@font-face {
  font-family: 'swis721_blkex_btblack';
  src: url(/static/media/swissek-webfont.0e33060b.woff2) format("woff2"), url(/static/media/swissek-webfont.9b53eabe.woff) format("woff");
  font-weight: normal;
  font-style: normal; }

.text {
  font-family: "swis721_blkex_btblack";
  text-decoration: none; }
  .text.-secondary {
    font-family: "Raleway", sans-serif; }
  .text.-uppercase {
    text-transform: uppercase; }
  .text.-center {
    text-align: center; }
  .text.-left {
    text-align: left; }
  .text.-bold {
    font-weight: 900; }
  .text.-light {
    font-weight: 300; }
  .text.-light-hover {
    font-weight: 300; }
    .text.-light-hover:hover {
      font-weight: 600; }
  .text.-shadow {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4); }
  .text.-black {
    color: #000; }
  .text.-white {
    color: #FFF; }
  .text.-xxl {
    font-size: 30px; }
    @media (min-width: 768px) {
      .text.-xxl {
        font-size: 34px; } }
  .text.-xl {
    font-size: 22px; }
    @media (min-width: 768px) {
      .text.-xl {
        font-size: 24px; } }
  .text.-l {
    font-size: 18px; }
    @media (min-width: 768px) {
      .text.-l {
        font-size: 22px; } }
  .text.-s {
    font-size: 10px; }
    @media (min-width: 768px) {
      .text.-s {
        font-size: 16px; } }
  .text.-xs {
    font-size: 12px; }
    @media (min-width: 768px) {
      .text.-xs {
        font-size: 16px; } }
  .text.-xxs {
    font-size: 10px; }
    @media (min-width: 768px) {
      .text.-xxs {
        font-size: 12px; } }
  .text.-xsm {
    font-size: 12px; }
    @media (min-width: 768px) {
      .text.-xsm {
        font-size: 14px; } }
  .text.-pointer {
    cursor: none !important; }
  .text.-carniceria {
    color: #FF0057;
    font-family: "Noto Serif", serif; }
    .text.-carniceria:hover {
      font-weight: 900; }

html * {
  cursor: none !important; }

video {
  cursor: none !important; }

html,
body {
  overflow-x: hidden;
  cursor: none !important !important; }
  html .ljmdlk,
  html .kqCone,
  body .ljmdlk,
  body .kqCone {
    min-height: 100vh;
    overflow-y: auto; }
  html a,
  body a {
    cursor: none !important;
    text-decoration: none;
    color: #000; }
    html a:hover,
    body a:hover {
      cursor: none !important; }

.black-circle {
  cursor: none !important; }

.body {
  font-family: "swis721_blkex_btblack";
  overflow-x: hidden;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden; }
  .body:after {
    display: none;
    content: ''; }
  .body a {
    cursor: none !important;
    text-decoration: none;
    color: #000; }
    .body a:hover {
      cursor: none !important; }
  .body ul {
    -webkit-padding-start: 0;
            padding-inline-start: 0;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0; }
  .body h1,
  .body h2,
  .body h3,
  .body h4,
  .body h5,
  .body h6 {
    margin: 0; }
  .body .background-modal {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh; }
  .body .modal {
    padding: 20px;
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 11; }
    .body .modal.-video {
      padding: 0;
      width: calc(100% - 100px);
      height: calc(100% - 100px); }
  .body .close-modal-button {
    display: inline-block;
    position: fixed;
    z-index: 12;
    top: 20px;
    left: 50%;
    cursor: url(https://i.ibb.co/GPTVjWJ/add-3.png) 32 32, auto;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0); }
    .body .close-modal-button img {
      width: 30px; }

/** @define FlexEmbed */
/**
 * Flexible media embeds
 *
 * For use with media embeds – such as videos, slideshows, or even images –
 * that need to retain a specific aspect ratio but adapt to the width of their
 * containing element.
 *
 * Based on: http://alistapart.com/article/creating-intrinsic-ratios-for-video
 */
.FlexEmbed {
  display: block;
  overflow: hidden;
  position: relative; }

/**
 * The aspect-ratio hack is applied to an empty element because it allows
 * the component to respect `max-height`. Default aspect ratio is 1:1.
 */
.FlexEmbed-ratio {
  display: block;
  padding-bottom: 100%;
  width: 100%; }

/**
 * Modifier: 3:1 aspect ratio
 */
.FlexEmbed-ratio--3by1 {
  padding-bottom: calc(100% / 3); }

/**
 * Modifier: 2:1 aspect ratio
 */
.FlexEmbed-ratio--2by1 {
  padding-bottom: 50%; }

/**
 * Modifier: 16:9 aspect ratio
 */
.FlexEmbed-ratio--16by9 {
  padding-bottom: 56.25%; }

/**
 * Modifier: 4:3 aspect ratio
 */
.FlexEmbed-ratio--4by3 {
  padding-bottom: 75%; }

/**
 * Fit the content to the aspect ratio
 */
.FlexEmbed-content {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }

.c-footer {
  display: flex;
  position: fixed;
  bottom: 10px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  justify-content: space-between;
  width: calc(100% - 30px);
  z-index: 100;
  mix-blend-mode: exclusion !important; }
  @media (min-width: 768px) {
    .c-footer {
      bottom: 40px;
      width: calc(100% - 120px); } }
  .c-footer .hidden-mobile {
    display: none; }
    @media (min-width: 768px) {
      .c-footer .hidden-mobile {
        display: block; } }
  .c-footer .menu-text-item {
    color: black !important;
    text-shadow: -2px -2px 0 white, 2px -2px 0 white, -2px 2px 0 white, 2px 2px 0 white; }

@charset "UTF-8";
@font-face {
  font-family: 'swis721_blkex_btblack';
  src: url(/static/media/swissek-webfont.0e33060b.woff2) format("woff2"), url(/static/media/swissek-webfont.9b53eabe.woff) format("woff");
  font-weight: normal;
  font-style: normal; }

.text {
  font-family: "swis721_blkex_btblack";
  text-decoration: none; }
  .text.-secondary {
    font-family: "Raleway", sans-serif; }
  .text.-uppercase {
    text-transform: uppercase; }
  .text.-center {
    text-align: center; }
  .text.-left {
    text-align: left; }
  .text.-bold {
    font-weight: 900; }
  .text.-light {
    font-weight: 300; }
  .text.-light-hover {
    font-weight: 300; }
    .text.-light-hover:hover {
      font-weight: 600; }
  .text.-shadow {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4); }
  .text.-black {
    color: #000; }
  .text.-white {
    color: #FFF; }
  .text.-xxl {
    font-size: 30px; }
    @media (min-width: 768px) {
      .text.-xxl {
        font-size: 34px; } }
  .text.-xl {
    font-size: 22px; }
    @media (min-width: 768px) {
      .text.-xl {
        font-size: 24px; } }
  .text.-l {
    font-size: 18px; }
    @media (min-width: 768px) {
      .text.-l {
        font-size: 22px; } }
  .text.-s {
    font-size: 10px; }
    @media (min-width: 768px) {
      .text.-s {
        font-size: 16px; } }
  .text.-xs {
    font-size: 12px; }
    @media (min-width: 768px) {
      .text.-xs {
        font-size: 16px; } }
  .text.-xxs {
    font-size: 10px; }
    @media (min-width: 768px) {
      .text.-xxs {
        font-size: 12px; } }
  .text.-xsm {
    font-size: 12px; }
    @media (min-width: 768px) {
      .text.-xsm {
        font-size: 14px; } }
  .text.-pointer {
    cursor: none !important; }
  .text.-carniceria {
    color: #FF0057;
    font-family: "Noto Serif", serif; }
    .text.-carniceria:hover {
      font-weight: 900; }

html * {
  cursor: none !important; }

video {
  cursor: none !important; }

html,
body {
  overflow-x: hidden;
  cursor: none !important !important; }
  html .ljmdlk,
  html .kqCone,
  body .ljmdlk,
  body .kqCone {
    min-height: 100vh;
    overflow-y: auto; }
  html a,
  body a {
    cursor: none !important;
    text-decoration: none;
    color: #000; }
    html a:hover,
    body a:hover {
      cursor: none !important; }

.black-circle {
  cursor: none !important; }

.body {
  font-family: "swis721_blkex_btblack";
  overflow-x: hidden;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden; }
  .body:after {
    display: none;
    content: ''; }
  .body a {
    cursor: none !important;
    text-decoration: none;
    color: #000; }
    .body a:hover {
      cursor: none !important; }
  .body ul {
    -webkit-padding-start: 0;
            padding-inline-start: 0;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0; }
  .body h1,
  .body h2,
  .body h3,
  .body h4,
  .body h5,
  .body h6 {
    margin: 0; }
  .body .background-modal {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh; }
  .body .modal {
    padding: 20px;
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 11; }
    .body .modal.-video {
      padding: 0;
      width: calc(100% - 100px);
      height: calc(100% - 100px); }
  .body .close-modal-button {
    display: inline-block;
    position: fixed;
    z-index: 12;
    top: 20px;
    left: 50%;
    cursor: url(https://i.ibb.co/GPTVjWJ/add-3.png) 32 32, auto;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0); }
    .body .close-modal-button img {
      width: 30px; }

/** @define FlexEmbed */
/**
 * Flexible media embeds
 *
 * For use with media embeds – such as videos, slideshows, or even images –
 * that need to retain a specific aspect ratio but adapt to the width of their
 * containing element.
 *
 * Based on: http://alistapart.com/article/creating-intrinsic-ratios-for-video
 */
.FlexEmbed {
  display: block;
  overflow: hidden;
  position: relative; }

/**
 * The aspect-ratio hack is applied to an empty element because it allows
 * the component to respect `max-height`. Default aspect ratio is 1:1.
 */
.FlexEmbed-ratio {
  display: block;
  padding-bottom: 100%;
  width: 100%; }

/**
 * Modifier: 3:1 aspect ratio
 */
.FlexEmbed-ratio--3by1 {
  padding-bottom: calc(100% / 3); }

/**
 * Modifier: 2:1 aspect ratio
 */
.FlexEmbed-ratio--2by1 {
  padding-bottom: 50%; }

/**
 * Modifier: 16:9 aspect ratio
 */
.FlexEmbed-ratio--16by9 {
  padding-bottom: 56.25%; }

/**
 * Modifier: 4:3 aspect ratio
 */
.FlexEmbed-ratio--4by3 {
  padding-bottom: 75%; }

/**
 * Fit the content to the aspect ratio
 */
.FlexEmbed-content {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }

.l-not-found-page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: #FFF; }

.cursor {
  position: absolute;
  z-index: 90;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  mix-blend-mode: exclusion;
  pointer-events: none; }
  @media (max-width: 600px) {
    .cursor {
      display: none; } }

.main-cursor {
  width: 16px;
  height: 16px;
  z-index: 90;
  border-radius: 50%;
  background-color: black;
  border: 2px solid white; }
  @media (max-width: 600px) {
    .main-cursor {
      display: none; } }

.clickable-cursor {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid white;
  background-color: black;
  -webkit-animation: beat 0.5s infinite alternate;
          animation: beat 0.5s infinite alternate;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
  -webkit-transform-origin: 8px 8px;
          transform-origin: 8px 8px; }
  @media (max-width: 600px) {
    .clickable-cursor {
      display: none; } }

/* Heart beat animation */
@-webkit-keyframes beat {
  to {
    -webkit-transform: scale(1.2);
            transform: scale(1.2); } }
@keyframes beat {
  to {
    -webkit-transform: scale(1.2);
            transform: scale(1.2); } }

