html * {
  cursor: $cursor-empty;
}
video {
  cursor: $cursor-empty;
}
html,
body {
  overflow-x: hidden;
  cursor: $cursor-none !important;

  // .ljmdlk {
  //     perspective: inherit !important;
  // }

  // .kqCone {
  //   transform: none !important;
  // }

  .ljmdlk,
  .kqCone {
    min-height: 100vh;
    overflow-y: auto;
  }

  a {
    cursor: $cursor-empty;
    text-decoration: none;
    color: $black;

    &:hover {
      cursor: $cursor-empty;
    }
  }
}

.black-circle {
  cursor: $cursor-black;
}

.body {
  font-family: $primary-font;
  overflow-x: hidden;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;

  &:after {
    display: none;
    content: '';
  }

  a {
    cursor: $cursor-empty;
    text-decoration: none;
    color: $black;

    &:hover {
      cursor: $cursor-empty;
    }
  }

  ul {
    padding-inline-start: 0;
    margin-block-start: 0;
    margin-block-end: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  .background-modal {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    background-color: rgba($black, 0.5);
    width: 100vw;
    height: 100vh;
  }

  .modal {
    padding: $primary-padding;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 11;

    &.-video {
      padding: 0;
      width: calc(100% - 100px);
      height: calc(100% - 100px);
    }
  }

  .close-modal-button {
    display: inline-block;
    position: fixed;
    z-index: 12;
    top: $primary-padding;
    left: 50%;
    cursor: url(https://i.ibb.co/GPTVjWJ/add-3.png) 32 32, auto;
    transform: translate(-50%, 0);

    img {
      width: 30px;
    }
  }
}

/** @define FlexEmbed */

/**
 * Flexible media embeds
 *
 * For use with media embeds – such as videos, slideshows, or even images –
 * that need to retain a specific aspect ratio but adapt to the width of their
 * containing element.
 *
 * Based on: http://alistapart.com/article/creating-intrinsic-ratios-for-video
 */

.FlexEmbed {
  display: block;
  overflow: hidden;
  position: relative;
}

/**
 * The aspect-ratio hack is applied to an empty element because it allows
 * the component to respect `max-height`. Default aspect ratio is 1:1.
 */

.FlexEmbed-ratio {
  display: block;
  padding-bottom: 100%;
  width: 100%;
}

/**
 * Modifier: 3:1 aspect ratio
 */

.FlexEmbed-ratio--3by1 {
  padding-bottom: calc(100% / 3);
}

/**
 * Modifier: 2:1 aspect ratio
 */

.FlexEmbed-ratio--2by1 {
  padding-bottom: 50%;
}

/**
 * Modifier: 16:9 aspect ratio
 */

.FlexEmbed-ratio--16by9 {
  padding-bottom: 56.25%;
}

/**
 * Modifier: 4:3 aspect ratio
 */

.FlexEmbed-ratio--4by3 {
  padding-bottom: 75%;
}

/**
 * Fit the content to the aspect ratio
 */

.FlexEmbed-content {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
