@import url('https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,700,800,900');
@import url('https://fonts.googleapis.com/css?family=Noto+Serif:400,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;700&display=swap');

@font-face {
    font-family: 'swis721_blkex_btblack';
    src: url('./fonts/swissek-webfont.woff2') format('woff2'),
        url('./fonts/swissek-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

$primary-font: 'swis721_blkex_btblack';
$secondary-font: 'Raleway', sans-serif;
$carniceria-font: 'Noto Serif', serif;
$filters-font: 'Raleway', sans-serif;

.text {
    font-family: $primary-font;
    text-decoration: none;

    &.-secondary {
        font-family: $secondary-font;
    }

    &.-uppercase {
        text-transform: uppercase;
    }

    &.-center {
        text-align: center;
    }

    &.-left {
        text-align: left;
    }

    &.-bold {
        font-weight: 900;
    }

    &.-light {
        font-weight: 300;
    }

    &.-light-hover {
        font-weight: 300;

        &:hover {
            font-weight: 600;
        }
    }

    &.-shadow {
        text-shadow: 1px 1px 1px rgba($black, .4);
    }

    &.-black {
        color: $black;
    }

    &.-white {
        color: $white;
    }

    &.-xxl {
        font-size: 30px;

        @media(min-width: 768px) {
            font-size: 34px;
        }
    }

    &.-xl {
        font-size: 22px;

        @media(min-width: 768px) {
            font-size: 24px;
        }
    }

    &.-l {
        font-size: 18px;

        @media(min-width: 768px) {
            font-size: 22px;
        }
    }

    &.-s {
        font-size: 10px;

        @media(min-width: 768px) {
            font-size: 16px;
        }
    }

    &.-xs {
        font-size: 12px;

        @media(min-width: 768px) {
            font-size: 16px;
        }
    }

    &.-xxs {
        font-size: 10px;

        @media(min-width: 768px) {
            font-size: 12px;
        }
    }

    &.-xsm {
        font-size: 12px;

        @media(min-width: 768px) {
            font-size: 14px;
        }
    }

    &.-pointer {
        cursor: $cursor-pointer;
    }

    &.-carniceria {
        color: $carniceria-color;
        font-family: $carniceria-font;

        &:hover {
            font-weight: 900;
        }
    }
}
